import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import * as React from 'react';
import { LIMIT } from '../../utils/constants';

const columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'title',
    headerName: 'Article Title',
    width: 600,
    editable: false,
  },
  {
    field: 'publishedAt',
    headerName: 'Published Date/Time',
    type: 'dateTime',
    width: 250,
    editable: false,
    valueGetter: (params) => {
      let date = params.row.date;
      return moment(date).format('DD.MM.YYYY HH:mm:ss');
    },
    sortComparator: (v1, v2) => {
      return moment(v1, 'DD.MM.YYYY HH:mm:ss').format('X') - moment(v2, 'DD.MM.YYYY HH:mm:ss').format('X')
    }
  },
];
// Disable cell focus
const CustomDataGrid = styled(DataGrid)(() => ({
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus':
    {
      outline: 'none',
    },
}));

export default function ArticleList({
  articles,
  allowMultiSelect,
  onPageChange = (page, detail) => {},
  onSelectionModelChange = (newSelectionModel) => {},
  loading = false,
  onRowClick = () => {},
  selectionModel,
  count,
  page,
  onSortModelChange = () => {}
}) {
  return (
    <Box
      sx={{
        height: 650,
        width: '100%',
        backgroundColor: '#233246',
      }}
    >
      <CustomDataGrid
        pagination
        rows={articles}
        columns={columns}
        rowCount={count}
        pageSize={LIMIT}
        loading={loading}
        rowsPerPageOptions={[LIMIT]}
        checkboxSelection={allowMultiSelect}
        page={page}
        onPageChange={onPageChange}
        selectionModel={selectionModel}
        // onRowClick={onRowClick}
        keepNonExistentRowsSelected
        onSelectionModelChange={onSelectionModelChange}
        onSortModelChange={onSortModelChange}
      />
    </Box>
  );
}
