import { ArticleModel } from './article.model';
import { MMeta } from './Meta.model';

export class MResponseArticle {
  constructor(dataArticle, strapi_content_type) {
    this.data = [];
    this.meta = new MMeta();
    this.setData(dataArticle, strapi_content_type);
  }

  setData(data, strapi_content_type) {
    this.data = (data?.data || []).map((e) => new ArticleModel(e, strapi_content_type));
    this.meta = new MMeta(data?.meta || {});
  }
}
