import React, {useEffect, useState} from 'react';
import {Button, TextField, FormControlLabel, Checkbox, Container, Stack, Box, InputLabel, Select, MenuItem, Fade} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';
import {Alert} from "@mui/lab";
import { StepContext } from '../App';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const CognitoUserShow = ( { selectedUser, jwtToken, urlId } ) => {

    const store = React.useContext(StepContext);
    const initData = JSON.parse(localStorage.getItem('data'));
    

    const [organisations, setOrganisations] = useState( [] );
    const [selectedEmail, setSelectedEmail] = useState(selectedUser?.email || null );
    const [firstName, setFirstName] = useState( selectedUser?.user_data?.first_name || null );
    const [lastName, setLastName] = useState( selectedUser?.user_data?.last_name || null );
    const [preferredName, setPreferredName] = useState( selectedUser?.user_data?.preferred_name || null );
    const [companyName, setCompanyName] = useState( selectedUser?.user_data?.company_name || null );
    const [companyTeam, setCompanyTeam] = useState( selectedUser?.user_data?.company_team || null );
    const [companyRole, setCompanyRole] = useState( selectedUser?.user_data?.company_role || null );
    const [companyLocation, setCompanyLocation] = useState( selectedUser?.user_data?.company_location || null );
    const [isUserAdmin, setIsUserAdmin] = useState( selectedUser?.user_data?.user_groups?.includes( "admin" )  || false );
    const [organisationTag, setOrganisationTag] = useState( selectedUser?.user_data?.organisation_tag  || 'default' );
    const [userNotes, setUserNotes] = useState( selectedUser?.user_data?.user_notes || null )
    const [showAlertType, setShowAlertType] = useState( '' );
    const [errorMessage, setErrorMessage] = useState( '' );

    const history = useHistory();
    const confirm = useConfirm();


    useEffect(() => {
        if (jwtToken) {
            loadOrganisations();
        }
    }, [jwtToken]);

    useEffect(() => {
      if (initData) {
        setSelectedEmail(initData.selectedEmail);
        setFirstName(initData.firstName);
        setLastName(initData.lastName);
        setSelectedEmail(initData.selectedEmail);
        setPreferredName(initData.preferredName);
        setCompanyName(initData.companyName);
        setCompanyTeam(initData.companyTeam);
        setCompanyRole(initData.companyRole);
        setCompanyLocation(initData.companyLocation);
        setIsUserAdmin(initData.isUserAdmin);
        setOrganisationTag(initData.organisationTag);
        setUserNotes(initData.userNotes);
      }
    }, []);
    useEffect(() => {
      store.setValue({
        firstName: firstName,
        lastName: lastName,
        selectedEmail: selectedEmail,
        preferredName: preferredName,
        companyName: companyName,
        companyTeam: companyTeam,
        companyRole: companyRole,
        companyLocation: companyLocation,
        isUserAdmin: isUserAdmin,
        organisationTag: organisationTag,
        userNotes: userNotes,
      });
      //   eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      firstName,
      lastName,
      selectedEmail,
      preferredName,
      companyName,
      companyTeam,
      companyRole,
      companyLocation,
      isUserAdmin,
      organisationTag,
      userNotes,
    ]);
  
    useEffect(() => {
      localStorage.removeItem('data');
    }, []);

    function handleSubmit(event) {
        event.preventDefault();
        if (urlId === 'new') {
            createNewCognitoUser();
        } else if ( urlId ) {
            // editEmailGroup(+urlId);
            updateCognitoUser( urlId )
        }
    }


    // Create
    function createNewCognitoUser() {
        const payload = {
            email: selectedEmail,
            password: "",
            phone_number: null,
            first_name: firstName,
            last_name: lastName,
            preferred_name: preferredName,
            company: companyName,
            company_team: companyTeam,
            company_role: companyRole,
            company_location: companyLocation,
            organisation_tag: organisationTag,
            user_groups: getUserGroups( isUserAdmin ),
            user_notes: userNotes,
        };

        fetch(`${API_BASE_URL}/api/v1/sec/cognito/admin/user`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + jwtToken,
            },
        })
            .then((response) => {
                if ( response.status === 201 ) {
                    setShowAlertType('success');
                    // return response.json();
                    history.push('/cognito-users');
                    return response.json();
                } else {
                    setShowAlertType('error');
                    return response.text().then( text => {
                        throw new Error(text)
                    });
                }
            })
            .catch( (err) => {
                setErrorMessage( err )
            } );
    }

    // Edit
    function updateCognitoUser( cognitoUserUid ) {
        const data_payload = {
            uid: cognitoUserUid,
            email: selectedEmail,
            phone_number: null,
            first_name: firstName,
            last_name: lastName,
            preferred_name: preferredName,
            user_groups: getUserGroups( isUserAdmin ),
            user_notes: userNotes,
            company_name: companyName,
            company_team: companyTeam,
            company_role: companyRole,
            company_location: companyLocation,
            organisation_tag: organisationTag,
        };

        const payload = {
            sub: cognitoUserUid,
            email: selectedEmail,
            user_data: data_payload
        };

        fetch(
            `${API_BASE_URL}/api/v1/sec/cognito/admin/user/${cognitoUserUid}`,
            {
                method: 'PUT',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + jwtToken,
                },
            },
        )
            .then((response) => {
                if ( response.status === 200 ) {
                    setShowAlertType('success');
                    // return response.json();
                    history.push('/cognito-users');
                    return response.json();
                } else {
                    setShowAlertType('error');
                    return response.text().then( text => {
                        throw new Error(text)
                    });
                }
            })
            .catch( (err) => {
                setErrorMessage( err )
            } );
            // .then((response) => response.json())
            // .then((data) => console.log(data))
            // .catch((err) => setShowAlertType('error-saved'));
    }


    function loadOrganisations() {
        fetch(`${API_BASE_URL}/api/v1/sec/organisation/all`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + jwtToken,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                return data.filter((o) => o.deletedAt === null);
            })
            .then((data) => {
                if (!data.error) {
                    let newData = sortArray(data);
                    setOrganisations(newData);

                    function sortArray(arr) {
                        arr.sort((a, b) => {
                            const nameA = a.tag.toLowerCase() || a.name.toLowerCase();
                            const nameB = b.tag.toLowerCase() || b.name.toLowerCase();
                            if (nameA < nameB) return -1;
                            if (nameA > nameB) return 1;
                            return 0;
                        });
                        return arr;
                    }
                } else {
                    console.error(data);
                }
            }).catch((err) => console.error( err ));
    }




    function getUserGroups( isAdmin ) {
        if ( isAdmin === true ) {
            return ["admin", "user"]
        } else {
            return ["user"]
        }
    }

    // Delete confirmation

    const handleDeleteClickConfirmed = (cognitoUserUid) => {
        confirm({ description: 'The user with uid `' + cognitoUserUid + '` will be permanently deleted ' +
                'from the AWS Cognito user pool and will not have access to the WCA web platform anymore...' })
            .then(() => {
                // console.log( "Delete user with uid " + cognitoUserUid + "..."  );
                handleDelete(cognitoUserUid);
            })
            .catch(() => { console.log( "Nope, not delete user with uid " + cognitoUserUid + " from AWS Cognito..." ) });
    };

    // Delete
    function handleDelete( cognitoUserUid ) {
        fetch(
            `${API_BASE_URL}/api/v1/sec/cognito/admin/user/${cognitoUserUid}`,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + jwtToken,
                },
            },
        )
            .then((response) => response.json())
            .then(history.push('/cognito-users'))
            .catch((err) => setShowAlertType('error-delete'));
    }


    const handleDeactivateClickConfirmed = (cognitoUserUid) => {
        confirm({ description: 'The user with uid `' + cognitoUserUid + '` will be deactivated and will not be able to ' +
                'access the WCA web platform and will not receive emails from WCA anymore...' })
            .then(() => {
                // console.log( "Delete user with uid " + cognitoUserUid + "..."  );
                handleDeactivate(cognitoUserUid);
            })
            .catch(() => { console.log( "Nope, not deactivating user with uid " + cognitoUserUid + "..." ) });
    };

    function handleDeactivate( cognitoUserUid ) {
        fetch(
            `${API_BASE_URL}/api/v1/sec/cognito/admin/user/${cognitoUserUid}`,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + jwtToken,
                },
            },
        )
            .then((response) => response.json())
            .then(history.push('/cognito-users'))
            .catch((err) => setShowAlertType('error-delete'));
    }


    function handleGoBackToList() {
        history.push('/cognito-users');
    }


    return (
        <form onSubmit={handleSubmit}>
            <Container maxWidth="sm" sx={{ marginLeft: '0px' }}>
                <Stack spacing={3} mb={3}>
                    <TextField
                        id="outlined-basic"
                        value={selectedEmail}
                        label="User e-mail"
                        variant="outlined"
                        onChange={(event) => setSelectedEmail(event.target.value)}
                        InputProps={{
                            readOnly: urlId !== 'new'
                        }}
                    />
                    <TextField
                        id="outlined-basic"
                        value={firstName}
                        label="First Name"
                        variant="outlined"
                        onChange={(event) => setFirstName(event.target.value)}
                    />
                    <TextField
                        id="outlined-basic"
                        value={lastName}
                        label="Last Name"
                        variant="outlined"
                        onChange={(event) => setLastName(event.target.value)}
                    />
                    <TextField
                        id="outlined-basic"
                        value={preferredName}
                        label="Preferred Name"
                        variant="outlined"
                        onChange={(event) => setPreferredName(event.target.value)}
                    />
                    <FormControlLabel
                        value={isUserAdmin}
                        control={<Checkbox
                            id="outlined-basic"
                            checked={isUserAdmin}
                            onChange={(event) => setIsUserAdmin(event.target.checked)}
                        />}
                        label="Platform Administrator"
                        labelPlacement="start"
                    />

                    <InputLabel>User Details</InputLabel>
                    <TextField
                        id="outlined-basic"
                        value={companyName}
                        label="Company"
                        variant="outlined"
                        onChange={(event) => setCompanyName(event.target.value)}
                    />
                    <TextField
                        id="outlined-basic"
                        value={companyTeam}
                        label="Company Team"
                        variant="outlined"
                        onChange={(event) => setCompanyTeam(event.target.value)}
                    />
                    <TextField
                        id="outlined-basic"
                        value={companyLocation}
                        label="Location"
                        variant="outlined"
                        onChange={(event) => setCompanyLocation(event.target.value)}
                    />

                    <InputLabel>Assigned Organisation</InputLabel>
                    <Select
                        id="outlined-basic"
                        value={organisationTag}
                        label="Assigned Organisation"
                        onChange={(event) => setOrganisationTag(event.target.value)}
                    >
                        {organisations.map((organisation, index) => {
                            return (
                                <MenuItem value={organisation.tag} key={index}>{organisation.tag} | {organisation.name}</MenuItem>
                            )
                        })}
                    </Select>
                    <InputLabel>Internal Notes</InputLabel>
                    <TextField
                        id="outlined-basic"
                        fullWidth
                        multiline={true}
                        minRows={5}
                        value={userNotes}
                        label="User-specific Notes"
                        variant="outlined"
                        onChange={(event) => setUserNotes(event.target.value)}
                    />
                </Stack>
            </Container>
            <Button variant="contained" type="submit" sx={{ mr: 2, ml: 3 }}>
                {urlId === 'new' ? 'Create' : 'Save'}
            </Button>
            <Button variant="outlined" sx={{ mr: 2, ml: 0 }} onClick={() => handleDeleteClickConfirmed(urlId)}>
                Delete User
            </Button>
            <Button variant="outlined" sx={{ mr: 2, ml: 0 }} onClick={() => handleDeactivateClickConfirmed(urlId)}>
                Deactivate User
            </Button>
            <Button variant="outlined" sx={{ mr: 2, ml: 0 }} onClick={() => handleGoBackToList()}>
                    Cancel
            </Button>
            <Fade in={showAlertType !== ''}>
                <Box mt={3}>
                    {showAlertType === 'success-saved' && (
                        <Alert severity="success">
                            User saved successfully!
                        </Alert>
                    )}
                    {showAlertType === 'success-updated' && (
                        <Alert severity="success">
                            User updated successfully!
                        </Alert>
                    )}
                    {showAlertType === 'success-deleted' && (
                        <Alert severity="success">
                            User deleted successfully!
                        </Alert>
                    )}
                    {showAlertType === 'error-delete' && (
                        <Alert severity="error">
                            There was an issue trying to delete a user
                        </Alert>
                    )}
                    {showAlertType === 'error' && (
                        <Alert severity="error">
                            Error while trying to save/update an AWS cognito user: {errorMessage?.toString()}
                        </Alert>
                    )}
                </Box>
            </Fade>
        </form>
    );
};


export default CognitoUserShow;
