import { Box } from '@mui/material';
import { cloneDeep } from 'lodash';
import React, { useCallback } from 'react';
import ArticleList from '../components/email-list/ArticleList';
import useGetArticle from '../hooks/useGetArticle';

export default function Step1({
  articleType,
  setArticleType,
  apiBaseUrl,
  sx,
  onWatchSelectedRow,
  modelSelectionCached,
}) {
  //! State
  const valueTab = articleType;

  //! HOOK
  const [
    articles,
    page,
    loading,
    count,
    selectionModel,
    setSelectionModel,
    setPage,
    onSortModelChange,
  ] = useGetArticle(modelSelectionCached, valueTab);

  //! Effect
  const onRowClick = useCallback(
    (row) => {
      let nextSelectedRow = cloneDeep(selectionModel);
      if (valueTab === 'shortform') {
        if (nextSelectedRow.includes(row.id)) {
          nextSelectedRow = nextSelectedRow.filter((id) => id !== row.id);
        } else {
          nextSelectedRow = [...nextSelectedRow, row.id];
        }
      } else {
        nextSelectedRow = [row.id];
      }
      setSelectionModel(nextSelectedRow);
      onWatchSelectedRow &&
        onWatchSelectedRow(nextSelectedRow, { tab: valueTab, row, articles });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onWatchSelectedRow, selectionModel, valueTab, articles],
  );

  const onModelChange = useCallback(
    (newSelectionModel) => {
      setSelectionModel(newSelectionModel);
      onWatchSelectedRow &&
        onWatchSelectedRow(newSelectionModel, {
          tab: valueTab,
          row: {},
          articles,
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onWatchSelectedRow, selectionModel, valueTab, articles],
  );

  // useEffect(() => {
  //   refreshData();
  // }, [articleType]);

  //! Function
  const onPageChange = useCallback((nPage) => {
    setPage(nPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //! Render
  return (
    <Box sx={sx}>
      {/* <ArticleTypeSwitch
        articleType={articleType}
        setArticles={setArticles}
        setArticleType={setArticleType}
        apiBaseUrl={apiBaseUrl}
      /> */}
      {/* Longforms emails - only send ONE article */}
      {/* Shortform emails - can send MANY articles */}
      <ArticleList
        articles={articles}
        page={page}
        count={count}
        loading={loading}
        // handleRowClick={handleRowClick}
        allowMultiSelect={articleType === 'shortform'}
        onPageChange={onPageChange}
        onRowClick={onRowClick}
        selectionModel={selectionModel}
        onSelectionModelChange={onModelChange}
        onSortModelChange={onSortModelChange}
      />
    </Box>
  );
}

// const ArticleTypeSwitch = ({ articleType, setArticleType }) => {
//   return (
//     <ToggleButtonGroup
//       color="primary"
//       value={articleType}
//       exclusive
//       onChange={(event, value) => setArticleType(value)}
//       aria-label="text alignment"
//       sx={{ mb: 2 }}
//     >
//       <ToggleButton value="shortform" aria-label="centered">
//         Shortforms
//       </ToggleButton>
//       <ToggleButton value="longform" aria-label="centered">
//         Longform
//       </ToggleButton>
//     </ToggleButtonGroup>
//   );
// };
