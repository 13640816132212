import { Box } from '@mui/material';
import React, { useCallback } from 'react';
import ArticleList from '../../components/email-list/ArticleList';
import useGetArticle from '../../hooks/useGetArticle';
import { LONG_FORM, MACRO_WATCH, SHORT_FORM } from '../../utils/constants';

const EachTabArticle = React.memo((props) => {
  const { value, index, onWatchSelectedRow, modelSelectionCached } = props;
  const valueTab = value;

  const createType = () => {
    let endpoint;
    let strapi_content_type;
    switch (valueTab) {
      case 1:
        endpoint = 'macro-watche';
        strapi_content_type = MACRO_WATCH;
        break;
      case 2:
        endpoint = 'longform';
        strapi_content_type = LONG_FORM;
        break;
      default:
        endpoint = 'shortform';
        strapi_content_type = SHORT_FORM;
        break;
    }
    return { endpoint, strapi_content_type };
  };

  //! HOOK
  const [
    articles,
    page,
    loading,
    count,
    selectionModel,
    setSelectionModel,
    setPage,
    // setArticles,
    onSortModelChange,
  ] = useGetArticle(modelSelectionCached, createType().endpoint);
  //! Effect

  //! Function
  const onModelChange = useCallback(
    (newSelectionModel) => {
      setSelectionModel(newSelectionModel);
      onWatchSelectedRow &&
        onWatchSelectedRow(newSelectionModel, {
          tab: valueTab,
          row: {},
          articles,
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onWatchSelectedRow, selectionModel, valueTab, articles],
  );

  const onPageChange = useCallback((nPage) => {
    setPage(nPage);
  }, []);

  //! Render
  return (
    <div
      role="tabpanel"
      hidden={false}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      <Box p={3}>
        <ArticleList
          onSortModelChange={onSortModelChange}
          articles={articles}
          page={page}
          count={count}
          onPageChange={onPageChange}
          // onRowClick={onRowClick}
          allowMultiSelect
          selectionModel={selectionModel}
          loading={loading}
          onSelectionModelChange={onModelChange}
        />
      </Box>
    </div>
  );
});

export default React.memo(EachTabArticle);
