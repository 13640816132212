import { useEffect, useState } from 'react';
import { API_BASE_URL } from '../utils/constants';
import useGetCurrentSession from './useGetCurrentSession';

const useGetLoadWeekWithWigramEmailReceivers = () => {
  //! State
  const { jwtToken } = useGetCurrentSession();
  const [optedInEmails, setOptedInEmails] = useState([]);
  const [loading, setLoading] = useState(false);

  //! Effect
  useEffect(() => {
    const loadWeekWithWigramEmailReceivers = () => {
      setLoading(true);
      fetch(
        `${API_BASE_URL}/api/v1/sec/email/email-receipients/THE_WEEK_WITH_WIGRAM`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + jwtToken,
          },
        },
      )
        .then((response) => response.json())
        .then((data) => {
          if (!data.error) {
            setOptedInEmails(data.body);
            setLoading(false);
          } else {
            console.error(data);
            setLoading(false);
          }
        });
    };

    if (jwtToken) {
      loadWeekWithWigramEmailReceivers();
    }

  }, [jwtToken]);

  return {
    optedInEmails,
    loading,
  };
};
export default useGetLoadWeekWithWigramEmailReceivers;
