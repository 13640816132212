/* eslint-disable react-hooks/exhaustive-deps */
import { uniqBy } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { MResponseArticle } from '../model/ResponseArticle.model';
import cmsServices from '../services/cmsServices';
import { LIMIT, LONG_FORM, MACRO_WATCH, SHORT_FORM } from '../utils/constants';

const useGetArticle = (modelSelectionCached, valueTab) => {
  //! State
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectionModel, setSelectionModel] = useState(modelSelectionCached);
  const countRef = useRef(0);
  const pageRef = useRef(0);
  const sortRef = useRef({
    field: 'publishedAt',
    sort: 'DESC',
  });
  const articlesRef = useRef([]);

  //! Function
  // When articleType changes
  const fetchData = async (
    page = pageRef.current,
    field = sortRef.current.field,
    sort = sortRef.current.sort,
  ) => {
    try {
      setLoading(true);
      setArticles([]);
      let strapi_content_type;
      switch (valueTab) {
        case 'longform':
          strapi_content_type = LONG_FORM;
          break;
        case 'macro-watche':
          strapi_content_type = MACRO_WATCH;
          break;
        default:
          strapi_content_type = SHORT_FORM;
          break;
      }
      const responseArticle = await cmsServices.get(
        `/${valueTab}s?populate=tag_list.tag,authors.author,chart_packs,countries,pdf,chart_image,image,images,charts,country_list.country&pagination[page]=${page}&pagination[pageSize]=${LIMIT}&sort=${field}:${sort.toUpperCase()}`,
      );
      const parseData = new MResponseArticle(
        responseArticle?.data,
        strapi_content_type,
      );
      countRef.current = parseData.meta.total;
      const newArticle = uniqBy(
        [...articlesRef.current, ...parseData.data],
        'id',
      );
      articlesRef.current = newArticle;
      setArticles(newArticle);
      // setLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const setPage = (page) => {
    pageRef.current = page;
    fetchData(page + 1);
  };

  const refresh = () => {
    pageRef.current = 0;
    fetchData(1);
  };
  // Call api sort by date
  const onSortModelChange = (el) => {
    pageRef.current = 0;
    sortRef.current = {
      field: el?.[0]?.field || 'publishedAt',
      sort: el?.[0]?.sort || 'DESC',
    };
    refresh();
  };
  //! Effect
  useEffect(() => {
    fetchData(pageRef.current + 1);
  }, []);

  useEffect(() => {
    refresh();
  }, [valueTab]);

  //! Render
  return [
    articles,
    pageRef.current,
    loading,
    countRef.current,
    selectionModel,
    setSelectionModel,
    setPage,
    setArticles,
    onSortModelChange,
  ];
};

export default useGetArticle;
