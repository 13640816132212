export class MTagList {
  constructor(dataTagList) {
    this.id = undefined;
    this.name = undefined;
    this.createdAt = undefined;
    this.updatedAt = undefined;
    this.order = undefined;
    this.setData && this.setData(dataTagList);
  }
  setData(data) {
    this.id = data?.tag?.data?.id;
    this.name = data?.tag?.data?.attributes?.name;
    this.createdAt = data?.tag?.data?.attributes?.createdAt;
    this.updatedAt = data?.tag?.data?.attributes?.updatedAt;
    this.order = data?.tag?.data?.attributes?.order;
  }
}
