import { Amplify } from '@aws-amplify/core';
import { createTheme } from '@material-ui/core/styles';
// import combinedDataprovider from './data-providers/CombinedDataProvider'
// import { cognitoDataProvider } from './data-providers/CognitoDataProvider'
import { ConfirmProvider } from 'material-ui-confirm';
import simpleRestProvider from 'ra-strapi-rest';
import * as React from 'react';
import {
  Admin,
  BooleanField,
  Datagrid,
  DateField,
  defaultTheme,
  List,
  Resource,
  TextField,
} from 'react-admin';
// import { CognitoGroupList, CognitoUserList, CognitoUserShow, buildAuthProvider } from 'react-admin-amplify';
import { buildAuthProvider } from 'react-admin-amplify';
// import jsonServerProvider from 'ra-data-json-server';
import awsExports from './aws-exports';
import cognito from './cognito-new';
import Dashboard from './dashboard/Dashboard';
import emailGroups from './email-groups-new';
import { LayoutAdmin } from './layout';
import organisations from './organisation-new';
import plotyChart from './chart-builder';
import customRoutes from './routes';
import sendEmail from './send-email';
import './App.css';

const customTheme = createTheme({
  ...defaultTheme,
  ...{
    palette: {
      type: 'dark',
      background: {
        default: '#1c2633',
      },
      primary: {
        main: '#4AA842',
        contrastText: '#222222',
      },
      secondary: {
        main: '#18212D',
        contrastText: '#ffffff',
      },
    },
  },
});

export const PostList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="title" />
      <DateField source="published_at" />
      <TextField source="category" />
      <BooleanField source="commentable" />
    </Datagrid>
  </List>
);

Amplify.configure(awsExports);

// const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const CMS_BASE_URL = process.env.REACT_APP_CMS_API_BASE_URL;

const dataProvider = simpleRestProvider(CMS_BASE_URL);
// const dataProvider = simpleRestProvider( API_BASE_URL + "/api/v1/sec/cognito/admin/user" );
export const StepContext = React.createContext();

const App = () => {
  const [value, setValue] = React.useState();
  const [clickRefesh, setClickRefesh] = React.useState(false);
  const store = { value, setValue, clickRefesh, setClickRefesh };
  return (
    <StepContext.Provider value={store}>
      <ConfirmProvider>
        <Admin
          authProvider={buildAuthProvider({
            authGroups: ['admin'],
            enableAdminQueries: true,
          })}
          dataProvider={dataProvider}
          // dataProvider={buildDataProvider({ queries, mutations })}
          // dashboard={Dashboard( RX_API_VERSION, STRAPI_VERSION )}
          dashboard={Dashboard}
          theme={customTheme}
          customRoutes={customRoutes}
          layout={LayoutAdmin}
        >
          <Resource name="organisations" {...organisations} />
          <Resource name="cognito-users" {...cognito} />
          <Resource name="email-groups" {...emailGroups} />
          <Resource name="send-email" {...sendEmail} />
          <Resource name="chart" {...plotyChart} />
        </Admin>
      </ConfirmProvider>
    </StepContext.Provider>
  );
};
export default App;
