import React from 'react';
import {
    DataGrid,
    GridActionsCellItem,
    GridToolbarContainer,
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import {styled} from "@mui/material/styles";
import moment from 'moment';

const OrganisationList = ({ organisations }) => {
    const history = useHistory();

    const columns = [
        { field: 'tag', headerName: 'Organisation Tag', width: 250 },
        { field: 'name', headerName: 'Organisation Name', width: 350 },
        { field: 'description', headerName: 'Description', width: 450 },
        { field: 'updatedAt', headerName: 'Last modified at (UTC)', width: 200, valueGetter: (params) => {
                let date = params.row.updatedAt;
                return moment( date ).format('DD.MM.YYYY HH:mm:ss');
            }},
        { field: 'createdAt', headerName: 'Created at (UTC)', width: 200, valueGetter: (params) => {
                let date = params.row.createdAt;
                return moment( date ).format('DD.MM.YYYY HH:mm:ss');
            }},
        { field: 'data_download_allowed', headerName: 'Data Download allowed', width: 200 },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        onClick={handleEditClick( id )}
                        color="inherit"
                    />,
                    // <GridActionsCellItem
                    //     icon={<DeleteIcon />}
                    //     label="Delete"
                    //     onClick={handleDeleteClick( id )}
                    //     color="inherit"
                    // />
                ];
            },
        },
    ];

    const handleEditClick = (urlId) => (event) => {
        history.push(`/organisations/${urlId}`);
    };

    // const handleDeleteClick = (urlId) => (event) => {
    //     history.push(`/cognito-users/${urlId}`);
    // };

    // make sure cells are not selectable...
    const CustomDataGrid = styled(DataGrid)(() => ({
        '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus':
            {
                outline: 'none',
            },
    }));

    function OrgGridToolbar(props) {
        const handleClick = () => {
            history.push(`/organisations/new`);
        };

        return (
            <GridToolbarContainer>
                <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                    Add New WCA-Live Organisation
                </Button>
            </GridToolbarContainer>
        );
    }

    return (
        <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ flexGrow: 1 }}>
                <CustomDataGrid
                    rows={organisations}
                    getRowId={row => row.tag}
                    columns={columns}
                    components={{
                        Toolbar: OrgGridToolbar,
                    }}
                />
            </div>
        </div>
    );
};

export default OrganisationList;
