// import CreateIcon from '@material-ui/icons/Create';
// import { CardHeader, CardContent } from '@material-ui/core';
import { Button, Card, CardContent } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import { Title } from 'react-admin';
// import {yellow} from "@material-ui/core/colors";

const CMS_API_BASE_URL = process.env.REACT_APP_CMS_BASE_URL;
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const LIVE_WEBSITE_URL = process.env.REACT_APP_LIVE_WEBSITE_URL;
// const STRAPI_VERSION = 'v3.6.8';

const Dashboard = () => {
  const [info, setInfo] = useState();
  const [status, setStatus] = useState('');

  useEffect(() => {
    fetch(`${API_BASE_URL}/actuator/info`)
      .then((response) => response.json())
      .then((data) => {
        setInfo(data.app);
      });
    fetch(`${API_BASE_URL}/actuator/health`)
      .then((response) => response.json())
      .then((data) => {
        setStatus(data.status);
      });
  }, []);

  return (
    <>
    <Card>
      <Title title="WCA Platform | Admin Dashboard" />
      <CardContent>
        <Typography variant="h4" size="sm">
          Wigram Capital Advisors Administration Portal
        </Typography>
      </CardContent>

      <CardContent>
        <div>
          <Typography variant="h6" size="sm">
            A link to the Content Management System
          </Typography>
        </div>
        <Button color="primary" variant="contained" href={CMS_API_BASE_URL}>
          Open CMS/Strapi
        </Button>
      </CardContent>
      <CardContent>
        <div>
          <Typography variant="h6" size="sm">
            A link to the Wigram Live Site
          </Typography>
        </div>
        <Button color="primary" variant="contained" href={LIVE_WEBSITE_URL}>
          Open WCA Live
        </Button>
      </CardContent>
    </Card>

      <Card style={{ marginTop: 20 }}>
        <CardContent>
          <Typography variant="h4" size="sm">
            Wigram's Reactive REST API
          </Typography>
        </CardContent>
        <CardContent>
          <Typography variant="h6" size="sm">
           Version: {info?.version}
          </Typography>
        </CardContent>
        <CardContent>
          <div>
            <Typography variant="h6" size="sm">
            API Server Information: {info?.name}
            </Typography>
          </div>
        </CardContent>

        <CardContent>
          <div style={{display:'flex', flexDirection:'row'}}>
            <Typography variant="h6" size="sm">
              Status:
            </Typography>
            <Typography variant="h6" size="sm" color={status === 'UP' ? 'primary' : 'red'}>
              {status}
            </Typography>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default Dashboard;
