import { Alert } from '@mui/lab';
import {
  Box,
  Button,
  Fade,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Tab,
  Tabs,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Title } from 'react-admin';
import { StepContext } from '../App';
import useGetCurrentSession from '../hooks/useGetCurrentSession';
import {
  API_BASE_URL,
  CMS_API_BASE_URL,
  LONG_FORM,
  MACRO_WATCH,
  SHORT_FORM,
} from '../utils/constants';
import EachTabArticle from './components/EachTabArticle';
import Step2 from './steps/Step2';
import Step3 from './steps/Step3';
import Step4 from './steps/Step4';

if (!CMS_API_BASE_URL) {
  console.error('CMS_BASE_URL is not set!');
}
if (!API_BASE_URL) {
  console.error('API_BASE_URL is not set!');
}

//! Main component
const EmailWeekly = () => {
  //! UseContext
  const store = useContext(StepContext);

  //! Local
  const initData = JSON.parse(localStorage.getItem('data'));

  //! State
  const { jwtToken } = useGetCurrentSession();
  const [activeStep, setActiveStep] = React.useState(0);
  const [showAlertType, setShowAlertType] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [valueTab, setValueTab] = useState(0);

  const [step2Data, setStep2Data] = useState(initData?.step2Data || {});
  const [step3Data, setStep3Data] = useState(initData?.step3Data || {});
  const [selectedRows, setSelectedRows] = useState(
    initData?.selectedRows || [],
  );

  //! Cached
  const [shortFormSelected, setShortFormSelected] = useState(
    initData?.shortFormSelected || [],
  );
  const [macroFormSelected, setMacroFormSelected] = useState(
    initData?.macroFormSelected || [],
  );
  const [longFormSelected, setLongFormSelected] = useState(
    initData?.longFormSelected || [],
  );

  const isPassStep1 = useMemo(() => {
    return (
      shortFormSelected.length !== 0 ||
      macroFormSelected.length !== 0 ||
      longFormSelected.length !== 0
    );
  }, [shortFormSelected, macroFormSelected, longFormSelected]);

  //! Refesh Click
  useEffect(() => {
    store.setValue({
      step2Data,
      step3Data,
      selectedRows,
      shortFormSelected,
      macroFormSelected,
      longFormSelected,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.clickRefesh]);
  useEffect(() => {
    store.setValue({
      step2Data,
      step3Data,
      selectedRows,
      shortFormSelected,
      macroFormSelected,
      longFormSelected,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.removeItem('data');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //! Effect
  const theme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#4AA842',
        contrastText: '#222222',
      },
    },
  });

  const tabs = useMemo(() => {
    return [
      {
        label: 'Shortforms',
        index: 0,
        type: SHORT_FORM,
      },
      {
        label: 'Macro Watches',
        index: 1,
        type: MACRO_WATCH,
      },
      {
        label: 'Longforms',
        index: 2,
        type: LONG_FORM,
      },
    ];
  }, []);

  const modelSelectionCached = useMemo(() => {
    if (valueTab === 0) {
      return shortFormSelected;
    }

    if (valueTab === 1) {
      return macroFormSelected;
    }

    if (valueTab === 2) {
      return longFormSelected;
    }
  }, [valueTab, shortFormSelected, macroFormSelected, longFormSelected]);

  //! Function
  const onWatchStep2 = useCallback(
    ({
      weekWithWigramTitle,
      weekWithWigramDescription,
      emailFrom,
      subject,
    }) => {
      setStep2Data({
        weekWithWigramTitle,
        weekWithWigramDescription,
        emailFrom,
        subject,
      });
    },
    [],
  );

  const onWatchStep3 = useCallback(
    (data) => {
      setStep3Data(data);
    },
    [],
  );

  const onWatchSelectedRow = useCallback(
    (nextRowsSelected, { tab, row, articles }) => {
      if (tab === 0) {
        setShortFormSelected(nextRowsSelected);
      } else if (tab === 1) {
        setMacroFormSelected(nextRowsSelected);
      } else {
        setLongFormSelected(nextRowsSelected);
      }
      const currentType = tabs[valueTab].type;
      let tempRowsSeleted = articles.filter((e) =>
        nextRowsSelected.includes(e.id),
      );
      const removeInclude = selectedRows.filter((el) => {
        return el.strapi_content_type !== currentType;
      });

      setSelectedRows([...removeInclude, ...tempRowsSeleted]);
    },
    [selectedRows, tabs, valueTab],
  );

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, []);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const sendEmail = () => {
    const {
      emailFrom,
      weekWithWigramTitle,
      weekWithWigramDescription,
      subject,
    } = step2Data;
    const { emailTo, emailsTo } = step3Data;

    const requestBody = {
      email_receivers: emailTo ? [emailTo] : emailsTo,
      subject: subject || 'Week with Wigram',
      email_sender: emailFrom,
      headline_title: weekWithWigramTitle,
      wca_content: weekWithWigramDescription,
      strapi_macrowatch_article_ids: macroFormSelected,
      strapi_shortform_article_ids: shortFormSelected,
      strapi_longform_article_ids: longFormSelected,
      ordered_articles: selectedRows
        // .map((el) => el.row)
        .map((el, index) => {
          return {
            order: index + 1,
            strapi_article_id: el.id,
            strapi_content_type: el.strapi_content_type,
          };
        }),
    };

    fetch(
      `${API_BASE_URL}/api/v1/sec/email/send-templated-weekwithwigram-email`,
      {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + jwtToken,
        },
      },
    )
      .then((response) => {
        if (response.ok) {
          setShowAlertType('success');
          return response.json();
        } else {
          const status = response.status;
          setShowAlertType('error');
          
          if (status === 400) {
            setErrorMsg(response.statusText);
          }
        }
      })
      .then((data) => console.log(data))
      .catch((err) => {
        setShowAlertType('error');
      });
  };

  //! Render
  return (
    <ThemeProvider theme={theme}>
      <Title title="WCA Platform | Email Manager | The Week with Wigram" />

      <Box sx={{ p: 2 }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          <Step>
            <StepLabel>
              Choose Articles (Shortforms and/or Macrowatches)
            </StepLabel>
            <StepContent>
              <Box sx={{ p: 2 }}>
                <Tabs
                  value={valueTab}
                  onChange={handleChangeTab}
                  aria-label="basic tabs example"
                >
                  {tabs.map((tab) => (
                    <Tab key={tab.label} label={tab.label} />
                  ))}
                </Tabs>
                {tabs.map((tab) => {
                  return (
                    tab.index === valueTab && (
                      <EachTabArticle
                        key={tab.index}
                        value={valueTab}
                        index={tab.index}
                        onWatchSelectedRow={onWatchSelectedRow}
                        modelSelectionCached={modelSelectionCached}
                      />
                    )
                  );
                })}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  sx={{ mt: 1, mr: 1 }}
                  disabled={!isPassStep1}
                >
                  Continue
                </Button>
              </Box>
            </StepContent>
          </Step>

          <Step>
            <StepLabel>
              The week with Wigram | Email specific Metadata
            </StepLabel>
            {activeStep === 1 && (
              <StepContent>
                <Box sx={{ p: 2 }}>
                  <Step2
                    sx={{ mb: 2 }}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    onWatchStep2={onWatchStep2}
                  />
                  <div>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 3, mr: 1 }}
                    >
                      Continue
                    </Button>
                    <Button onClick={handleBack} sx={{ mt: 3, mr: 1 }}>
                      Back
                    </Button>
                  </div>
                </Box>
              </StepContent>
            )}
          </Step>

          <Step>
            <StepLabel>Send To</StepLabel>
            {activeStep === 2 && (
              <StepContent>
                <Box sx={{ p: 2 }}>
                  <Step3 sx={{ mb: 2 }} onWatchStep3={onWatchStep3} />

                  <div>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 3, mr: 1 }}
                    >
                      Continue
                    </Button>
                    <Button onClick={handleBack} sx={{ mt: 3, mr: 1 }}>
                      Back
                    </Button>
                  </div>
                </Box>
              </StepContent>
            )}
          </Step>

          <Step>
            <StepLabel>Review & Send</StepLabel>
            {activeStep === 3 && (
              <StepContent>
                <Box sx={{ p: 2 }}>
                  <div>
                    <Step4
                      // articlesToSend={selectedRows.map((el) => el.row)}
                      articlesToSend={selectedRows}
                      sendToTabValue={step3Data.sendToTabValue}
                      emailTo={step3Data.emailTo}
                      emailsTo={step3Data.emailsTo}
                      emailFrom={step2Data.emailFrom}
                      weekWithWigramTitle={step2Data.weekWithWigramTitle}
                      weekWithWigramDescription={
                        step2Data.weekWithWigramDescription
                      }
                      subject={step2Data.subject}
                      selectedEmailGroup={step3Data.selectedEmailGroup}
                    />

                    <Button
                      variant="contained"
                      onClick={sendEmail}
                      sx={{ mt: 2, mr: 1 }}
                    >
                      Confirm & Send Emails
                    </Button>
                    <Button onClick={handleBack} sx={{ mt: 2, mr: 1 }}>
                      Back
                    </Button>
                  </div>
                  <Fade in={showAlertType !== ''}>
                    <Box mt={3}>
                      {showAlertType === 'success' && (
                        <Alert severity="success">
                          Emails sent successfully!
                        </Alert>
                      )}
                      {showAlertType === 'error' && (
                        <Alert severity="error">
                          {errorMsg || 'There was an issue trying to send emails'}
                        </Alert>
                      )}
                    </Box>
                  </Fade>
                </Box>
              </StepContent>
            )}
          </Step>
        </Stepper>
      </Box>
    </ThemeProvider>
  );
};

export default EmailWeekly;
