import { useEffect, useState } from 'react';
import apiService from '../services/apiServices';
import { organisationUrl } from '../services/apiUrl';
import useGetCurrentSession from './useGetCurrentSession';

const useGetOrganisations = () => {
  const [organisations, setOrganisations] = useState([]);
  const [error, setError] = useState('');
  const { jwtToken } = useGetCurrentSession();
  useEffect(() => {
    const loadOrganisations = () => {
      apiService
        .get(organisationUrl)
        .then((res) =>
          // Don't allow user_data value to be empty. Empty user_data means they are not in our DB...
          res?.data?.filter((o) => o.deletedAt === null),
        )
        .then((data) => {
          if (!data.error) {
            setOrganisations(data);
          } else {
            setError(data);
          }
        })
        .catch((err) => setError(err));
    };
    if (jwtToken) {
      loadOrganisations();
    }
  }, [jwtToken]);
  return { organisations, error };
};

export default useGetOrganisations;
