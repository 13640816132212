import { Box, Link } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React, { useCallback, useEffect } from 'react';
import { Title } from 'react-admin';
import { useParams } from 'react-router-dom';
import { StepContext } from '../App';
import useGetCurrentSession from '../hooks/useGetCurrentSession';
import useGetOrganisations from '../hooks/useGetOrganisations';
import OrganisationList from './OrganisationList';
import OrganisationShow from './OrganisationShow';
// import {Alert} from "@mui/lab";

// Create a theme that's used for @mui components
const customTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#4AA842',
      contrastText: '#222222',
    },
  },
});

const OrganisationEntities = () => {
  //! State
  const { tag } = useParams();
  const { jwtToken } = useGetCurrentSession();
  const { organisations, error } = useGetOrganisations();
  const store = React.useContext(StepContext);
  const initData = JSON.parse(localStorage.getItem('data'));
  //! Effect
  useEffect(() => {
    if (initData) {
      store.setValue({
        selectedRow: [initData?.selectedRow],
      });
    }
    localStorage.removeItem('data');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRowClick = useCallback(
    (entry) => {
      store.setValue({
        selectedRow: [entry.row.tag],
      });
    },
    [store],
  );

  const renderError = () => {
    return <div>{error?.toString() || 'Error'}</div>;
  };

  const renderScreen = () => {
    return (
      <>
        {tag === undefined && (
          <OrganisationList
            organisations={organisations}
            onRowClick={onRowClick}
          />
        )}
        {tag !== undefined && organisations.length > 0 && (
          <OrganisationShow
            urlId={tag}
            selectedOrg={organisations.find((o) => o.tag === tag)}
            jwtToken={jwtToken}
          />
        )}
      </>
    );
  };

  //! Render
  return (
    <ThemeProvider theme={customTheme}>
      <Title title="WCA Platform | Organisations Manager" />
      <Box sx={{ pt: 2, mb: 5 }}>
        <TopNav urlId={tag} />
      </Box>
      {Boolean(error?.toString()) ? renderError() : renderScreen()}
    </ThemeProvider>
  );
};

const TopNav = ({ urlId }) => (
  <div role="presentation">
    <Breadcrumbs aria-label="breadcrumb">
      <Link underline="hover" href="/#/organisations">
        Organisations
      </Link>
      {urlId && (
        <Link underline="none" aria-current="page">
          Details
        </Link>
      )}
    </Breadcrumbs>
  </div>
);

export default OrganisationEntities;
