import { Box, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { StepContext } from '../../App';

const Step2 = ({ selectedRows = [], setSelectedRows, onWatchStep2 }) => {
  //! Context
  const store = useContext(StepContext);

  //! State
  const [subject, setSubject] = useState(store?.value?.step2Data.subject || '');
  const [weekWithWigramTitle, setWeekWithWigramTitle] = useState(
    store?.value?.step2Data?.weekWithWigramTitle || '',
  );
  const [weekWithWigramDescription, setWeekWithWigramDescription] = useState(
    store?.value?.step2Data?.weekWithWigramDescription || '',
  );
  const [emailFrom, setEmailFrom] = useState(
    store?.value?.step2Data?.emailFrom || 'research@wigramcap.com',
  );

  //! Effect
  useEffect(() => {
    onWatchStep2 &&
      onWatchStep2({
        subject,
        weekWithWigramTitle,
        weekWithWigramDescription,
        emailFrom,
      });
  }, [
    onWatchStep2,
    subject,
    weekWithWigramTitle,
    weekWithWigramDescription,
    emailFrom,
  ]);

  //! Function
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newItems = [...selectedRows];
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    setSelectedRows(newItems);
  };

  //! Render
  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1', mb: 4 }}>
        <TextField
          id="outlined-basic"
          label="Email Subject"
          variant="outlined"
          fullWidth
          value={subject}
          sx={{ width: '50ch' }}
          onChange={({ target: { value } }) => {
            setSubject(value);
          }}
        />
      </Box>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TextField
          id="outlined-basic"
          label="Email Title (Optional)"
          variant="outlined"
          fullWidth
          value={weekWithWigramTitle}
          sx={{ width: '50ch' }}
          onChange={({ target: { value } }) => {
            setWeekWithWigramTitle(value);
          }}
        />
      </Box>
      <Box sx={{ width: '100%', typography: 'body1', mt: 4 }}>
        <TextField
          id="outlined-basic"
          label="`Week with Wigram` e-mail description content (Optional)"
          variant="outlined"
          fullWidth
          multiline={true}
          minRows={10}
          value={weekWithWigramDescription}
          sx={{ mb: 2 }}
          onChange={({ target: { value } }) => {
            setWeekWithWigramDescription(value);
          }}
        />
      </Box>
      <Box
        sx={{
          width: '100%',
          typography: 'body1',
          p: 2,
          border: '1px solid #464e58',
          borderRadius: 1,
        }}
      >
        <h3>
          Article Title{' '}
          <span style={{ fontSize: '12px' }}>
            (Click and drag to reorder list)
          </span>
        </h3>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {selectedRows.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id.toString()}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <>
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {item.id} {item.title}
                        </div>
                      </>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Box>

      <Box sx={{ width: '100%', typography: 'body1', mt: 4 }}>
        <InputLabel id="demo-simple-select-standard-label">
          Email Sender
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={emailFrom}
          label="Age"
          onChange={({ target: { value } }) => {
            setEmailFrom(value);
          }}
        >
          <MenuItem value={'default'}>default</MenuItem>
          <MenuItem value={'rodney@wigramcap.com'}>
            rodney@wigramcap.com
          </MenuItem>
          <MenuItem value={'research@wigramcap.com'}>
            research@wigramcap.com
          </MenuItem>
        </Select>
      </Box>
    </>
  );
};

export default React.memo(Step2);
