import * as React from 'react';
import { Layout } from 'react-admin';
import Menu from './Menu';
import { CustomAppBar } from '.';
import { ConfirmProvider } from 'material-ui-confirm';

const LayoutAdmin = (props) => {
  return (
    <ConfirmProvider>
    <Layout
      {...props}
      appBar={CustomAppBar}
      menu={Menu}
      
      />
      </ConfirmProvider>
  );
};

export default LayoutAdmin;
