import { Box } from '@mui/material';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const StepReorder = ({ selectedRows = [], setSelectedRows }) => {
  //! Function
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newItems = [...selectedRows];
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    setSelectedRows(newItems);
  };

  //! Render
  return (
    <>
      <Box
        sx={{
          width: '100%',
          typography: 'body1',
          p: 2,
          border: '1px solid #464e58',
          borderRadius: 1,
        }}
      >
        <h3>
          Article Title{' '}
          <span style={{ fontSize: '12px' }}>
            (Click and drag to reorder list)
          </span>
        </h3>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {selectedRows.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id.toString()}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <>
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {item.id} {item.title}
                        </div>
                      </>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    </>
  );
};

export default React.memo(StepReorder);
