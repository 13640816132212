import axios from 'axios';
import { CMS_API_BASE_URL } from '../utils/constants';

const cmsServices = axios.create({
  baseURL: CMS_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 120000,
});

export const addToken = (token) => {
  cmsServices.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export default cmsServices;
