import { createTheme } from '@mui/system';
import React from 'react';

const LIMIT = 10;
export const PagingContext = React.createContext({});

export const SHORT_FORM = 'SHORT_FORM';
export const MACRO_WATCH = 'MACRO_WATCH';
export const LONG_FORM = 'LONG_FORM';

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// export const API_BASE_URL = 'https://api-dev.wigramcap.com'`;

export const CMS_BASE_URL = process.env.REACT_APP_CMS_BASE_URL;
// export const CMS_API_BASE_URL = 'https://cms-dev.wigramcap.com';
export const CMS_API_BASE_URL = process.env.REACT_APP_CMS_API_BASE_URL;
// export const CMS_API_BASE_URL = 'https://cms-dev.wigramcap.com';

export const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#4AA842',
      contrastText: '#222222',
    },
  },
});
// export const create a theme that's used for @mui components

export { LIMIT };
