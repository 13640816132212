import { useEffect, useState } from 'react';
import { API_BASE_URL } from '../utils/constants';
import useGetCurrentSession from './useGetCurrentSession';
import { sortByProperty } from '../utils/arrayHelpers'
import { sortBy } from 'lodash';

const useGetEmailGroups = () => {
  const { jwtToken } = useGetCurrentSession();
  const [emailGroups, setEmailGroups] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadEmailGroups = () => {
      setLoading(true);
      fetch(`${API_BASE_URL}/api/v1/sec/email/email-distribution-group/all`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + jwtToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.error) {
            let newArray;

            const formattedEmailGroups = data.body.map((g) => ({
              id: g.id,
              name: g.email_group_name,
              desc: g.email_group_description,
              userIds: g.user_ids,
              userCount: g.user_ids?.length || 0,
            }));

            newArray = sortBy(formattedEmailGroups, 'name');
            
            setEmailGroups(newArray);
            setLoading(false);
          } else {
            console.error(data);
            setLoading(false);
          }
        });
    };

    if (jwtToken) {
      loadEmailGroups();
    }
  }, [jwtToken]);

  return {
    emailGroups,
    loading,
  };
};

export default useGetEmailGroups;
