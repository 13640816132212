import { useEffect, useState } from 'react';
import { API_BASE_URL } from '../utils/constants';
import useGetCurrentSession from './useGetCurrentSession';

const useGetLoadUsers = () => {
  //! State
  const { jwtToken } = useGetCurrentSession();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);

  //! Effect
  useEffect(() => {
    const loadUsers = () => {
      setLoading(true);
      fetch(`${API_BASE_URL}/api/v1/sec/cognito/admin/user/all`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + jwtToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          // Don't allow user_data value to be empty. Empty user_data means they are not in our DB?
          return data.filter((u) => u.user_data !== null);
        })
        .then((data) => {
          if (!data.error) {
            setUsers(data);
            setLoading(false);
          } else {
            console.error(data);
            setLoading(false);
          }
        });
    };
    if (jwtToken) {
      loadUsers();
    }
  }, [jwtToken]);

  return {
    users,
    loading,
  };
};
export default useGetLoadUsers;
