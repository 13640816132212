import React, { useState, useEffect } from 'react';
import {Button, TextField, FormControlLabel, Checkbox, Container, Stack, Box, Fade} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';
import {Alert} from "@mui/lab";
import { StepContext } from '../App'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const OrganisationShow = ( { selectedOrg, jwtToken, urlId } ) => {

    const store = React.useContext(StepContext);
    const initData = JSON.parse(localStorage.getItem('data'));

    const [ selectedOrgId, setSelectedOrgId ] = useState( selectedOrg?.id || null );
    const [ selectedOrgTag, setSelectedOrgTag] = useState( selectedOrg?.tag || '' );
    const [ orgName, setOrgName] = useState( selectedOrg?.name || null );
    const [ orgDescription, setOrgDescription] = useState( selectedOrg?.description || null );
    const [ orgNotes, setOrgNotes] = useState( selectedOrg?.notes || null );
    const [ isOrgAllowedDataDownload, setIsOrgAllowedDataDownload ] = useState( selectedOrg?.data_download_allowed || false );
    const [ isOrgActive, setIsOrgActive ] = useState( selectedOrg?.organisation_active || true );
    const [showAlertType, setShowAlertType] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const history = useHistory();
    const confirm = useConfirm();

    useEffect(() => {
      if (initData) {
        setSelectedOrgId(initData.selectedOrgId);
        setSelectedOrgTag(initData.selectedOrgTag);
        setOrgName(initData.orgName);
        setOrgDescription(initData.orgDescription);
        setOrgNotes(initData.orgNotes);
        setIsOrgAllowedDataDownload(initData.isOrgAllowedDataDownload);
        setIsOrgActive(initData.isOrgActive);
        setShowAlertType(initData.showAlertType);
        setErrorMessage(initData.errorMessage);
      }
    }, []);
  
    useEffect(() => {
      store.setValue({
        selectedOrgId: selectedOrgId,
        selectedOrgTag: selectedOrgTag,
        orgName: orgName,
        orgDescription: orgDescription,
        orgNotes: orgNotes,
        isOrgAllowedDataDownload: isOrgAllowedDataDownload,
        isOrgActive: isOrgActive,
        showAlertType: showAlertType,
        errorMessage: errorMessage,
      });
  
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      selectedOrgId,
      selectedOrgTag,
      orgName,
      orgDescription,
      orgNotes,
      isOrgAllowedDataDownload,
      isOrgActive,
      showAlertType,
      errorMessage,
    ]);
  
    useEffect(()=> {
      localStorage.removeItem('data')
    },[])

    function handleSubmit(event) {
        event.preventDefault();
        if (urlId === 'new') {
            createNewOrganisation();
        } else if ( urlId ) {
            // editEmailGroup(+urlId);
            updateOrganisation( selectedOrgId );
        }
    }


    // Create
    function createNewOrganisation() {
        const payload = {
            id: null,
            tag: selectedOrgTag,
            name: orgName,
            description: orgDescription,
            notes: orgNotes,
            data_download_allowed: isOrgAllowedDataDownload,
            organisation_active: isOrgActive,
        };

        fetch(`${API_BASE_URL}/api/v1/sec/organisation`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + jwtToken,
            },
        })
            .then((response) => {
                if ( response.status === 201 ) {
                    history.push('/organisations');
                    return response.json();
                } else {
                    setShowAlertType('error');
                    return response.text().then( text => {
                        throw new Error(text);
                    });
                }
            })
            .catch( (err) => {
                setErrorMessage( err )
            } );
    }

    // Edit
    function updateOrganisation( recId ) {
        const payload = {
            id: recId,
            tag: selectedOrgTag,
            name: orgName,
            description: orgDescription,
            notes: orgNotes,
            data_download_allowed: isOrgAllowedDataDownload,
            organisation_active: isOrgActive,
        };

        fetch(
            `${API_BASE_URL}/api/v1/sec/organisation/${selectedOrgTag}`,
            {
                method: 'PUT',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + jwtToken,
                },
            },
        )
            .then((response) => {
                if ( response.status === 200 ) {
                    // setShowAlertType('success');
                    // return response.json();
                    history.push('/organisations');
                    return response.json();
                } else {
                    setShowAlertType('error');
                    return response.text().then( text => {
                        throw new Error(text)
                    });
                }
            })
            .catch( (err) => {
                setErrorMessage( err )
            } );
    }


    // Delete confirmation
    const handleDeleteClickConfirmed = (orgTag) => {
        confirm({ description: 'The Organisation with organisation tag `' + orgTag + '` will be permanently deleted ' +
                'from the database...' })
            .then(() => {
                handleDelete(orgTag);
            })
            .catch(() => { console.log( "Nope, not delete organisation with tag " + orgTag + " from database..." ) });
    };

    // Delete
    function handleDelete( orgTag ) {
        fetch(
            `${API_BASE_URL}/api/v1/sec/organisation/${orgTag}`,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + jwtToken,
                },
            },
        ).then((response) => {
                if ( response.status === 200 ) {
                    // setShowAlertType('success');
                    // return response.json();
                    history.push('/organisations');
                    return response.json();
                } else {
                    setShowAlertType('error-delete');
                    return response.text().then( text => {
                        throw new Error(text);
                    });
                }
            })
            .catch( (err) => {
                setErrorMessage( err );
            } );
    }

    function handleGoBackToList() {
        history.push('/organisations');
    }


    return (
        <form onSubmit={handleSubmit}>
            <Container maxWidth="sm" sx={{ marginLeft: '0px' }}>
                <Stack spacing={3} mb={3}>
                    <TextField
                        id="outlined-basic"
                        value={selectedOrgTag}
                        label="Unique Organisation Tag"
                        variant="outlined"
                        onChange={(event) => setSelectedOrgTag(event.target.value)}
                        InputProps={{
                            readOnly: urlId !== 'new'
                        }}
                    />
                    <TextField
                        id="outlined-basic"
                        value={orgName}
                        label="Organisation Name"
                        variant="outlined"
                        onChange={(event) => setOrgName(event.target.value)}
                    />
                    <TextField
                        id="outlined-basic"
                        value={orgDescription}
                        label="Organisation Description"
                        variant="outlined"
                        onChange={(event) => setOrgDescription(event.target.value)}
                    />
                    <TextField
                        id="outlined-basic"
                        fullWidth
                        multiline={true}
                        minRows={5}
                        value={orgNotes}
                        label="Organisation Notes"
                        variant="outlined"
                        onChange={(event) => setOrgNotes(event.target.value)}
                    />
                    <FormControlLabel
                        value={isOrgAllowedDataDownload}
                        control={<Checkbox
                            id="outlined-basic"
                            checked={isOrgAllowedDataDownload}
                            onChange={(event) => setIsOrgAllowedDataDownload(event.target.checked)}
                        />}
                        label="Organisation allowed to download data"
                        labelPlacement="start"
                    />
                </Stack>
            </Container>
            <Button variant="contained" type="submit" sx={{ mr: 2, ml: 3 }}>
                {urlId === 'new' ? 'Create' : 'Save'}
            </Button>
            <Button variant="outlined" onClick={() => handleDeleteClickConfirmed(urlId)}>
                Delete Organisation
            </Button>
            <Button variant="outlined" sx={{ mr: 2, ml: 2 }} onClick={() => handleGoBackToList()}>
                Cancel
            </Button>
            <Fade in={showAlertType !== ''}>
                <Box mt={3}>
                    {showAlertType === 'success-saved' && (
                        <Alert severity="success">
                            Organisation saved successfully!
                        </Alert>
                    )}
                    {showAlertType === 'success-updated' && (
                        <Alert severity="success">
                            Organisation updated successfully!
                        </Alert>
                    )}
                    {showAlertType === 'success-deleted' && (
                        <Alert severity="success">
                            User deleted successfully!
                        </Alert>
                    )}
                    {showAlertType === 'error' && (
                        <Alert severity="error">
                            Error while trying to save/update an Organisation: {errorMessage?.toString()}
                        </Alert>
                    )}
                    {showAlertType === 'error-delete' && (
                        <Alert severity="error">
                            Error while trying to delete an Organisation: {errorMessage?.toString()}
                        </Alert>
                    )}
                </Box>
            </Fade>
        </form>
    );
};


export default OrganisationShow;
