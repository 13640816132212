import React from 'react';
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';

const EmailGroupList = ({ emailGroups }) => {
  const history = useHistory();

  const columns = [
    { field: 'name', headerName: 'Group', width: 150 },
    { field: 'userCount', headerName: '# Clients', width: 150 },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const sortRef = { field: 'name', sortingOrder: ['ASC', 'DESC'] };

  const handleEditClick = (id) => (event) => {
    history.push(`/email-groups/${id}`);
  };

  function GridToolbar() {
    const handleClick = ({ id }) => {
      history.push(`/email-groups/new`);
    };

    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Add Email Group
        </Button>
      </GridToolbarContainer>
    );
  }

  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          rows={emailGroups}
          columns={columns}
          components={{
            Toolbar: GridToolbar,
          }}
          initialState={{
            sorting: { sortModel: [sortRef] },
          }}
        />
      </div>
    </div>
  );
};

export default EmailGroupList;
