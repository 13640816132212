import { useEffect, useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import { addToken } from '../services/apiServices';

const useGetCurrentSession = () => {
  const [jwtToken, setJwtToken] = useState('');

  useEffect(() => {
    Auth.currentSession().then((user) => {
      addToken(user?.accessToken?.jwtToken);
      setJwtToken(user?.accessToken?.jwtToken);
    });
  }, []);

  return { jwtToken };
};

export default useGetCurrentSession;
