import * as React from 'react';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import {
  List,
  MenuItem,
  ListItemIcon,
  Typography,
  Collapse,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useTranslate } from 'react-admin';

const useStyles = makeStyles((theme) => ({
  icon: { minWidth: theme.spacing(5) },
  sidebarIsOpen: {
    '& a': {
      transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
      paddingLeft: theme.spacing(4),
    },
  },
  sidebarIsClosed: {
    '& a': {
      transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
      paddingLeft: theme.spacing(2),
    },
  },
}));

// interface Props {
//     dense: boolean;
//     handleToggle: () => void;
//     icon: ReactElement;
//     isOpen: boolean;
//     name: string;
//     children: ReactNode;
// }

const SubMenu = (props) => {
  const { handleToggle, isOpen, name, icon, children, dense } = props;
  const translate = useTranslate();
  const classes = useStyles();
  const sidebarIsOpen = useSelector((state) => state.admin.ui.sidebarOpen);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const header = (
    <MenuItem dense={dense} button onClick={handleToggle} className='w-300' >
      <ListItemIcon className={classes.icon}>
        {isOpen ? <><ExpandMore/> {icon}</>: icon}
      </ListItemIcon>
      <Typography variant="inherit" color="textSecondary">
        {capitalizeFirstLetter(translate(name))}
      </Typography>
    </MenuItem>
  );

  return (
    <Fragment>
      {sidebarIsOpen || isOpen ? (
        header
      ) : (
        <Tooltip title={translate(name)} placement="right">
          {header}
        </Tooltip>
      )}
      <Collapse className='w-300' in={isOpen} timeout="auto" unmountOnExit>
        
        <List
          classes={'w-300'}
          dense={dense}
          component="div"
          disablePadding
          className={
            sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed
          }
        >
          {children}
        </List>
      </Collapse>
    </Fragment>
  );
};

export default SubMenu;
