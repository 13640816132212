import React, { useState, useEffect } from 'react';
import { Button, TextField, Container, Stack } from '@mui/material';
import TransferList from './TransferList';
import { useHistory } from 'react-router-dom';
import { StepContext } from '../App';
import { useConfirm } from 'material-ui-confirm';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const EmailGroupShow = ({ emailGroup, users, jwtToken, urlId }) => {
  const store = React.useContext(StepContext);
  const [groupName, setGroupName] = useState(emailGroup?.name || '');
  const [groupDesc, setGroupDesc] = useState(emailGroup?.desc || '');
  const [usersIdsInGroup, setUsersIdsInGroup] = useState(
    emailGroup?.userIds || [],
  );
  const history = useHistory();
  const confirm = useConfirm();

  useEffect(() => {
    setGroupName(emailGroup?.name || '');
    setGroupDesc(emailGroup?.desc || '');
    setUsersIdsInGroup(emailGroup?.userIds || []);
  }, [emailGroup]);

  useEffect(() => {
    store.setValue({
      groupName: groupName,
      groupDesc: groupDesc,
      usersIdsInGroup: usersIdsInGroup,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupName, groupDesc, usersIdsInGroup]);

  useEffect(() => {
    localStorage.removeItem('data');
  }, []);

  function handleSubmit(event) {
    event.preventDefault();
    if (urlId === 'new') {
      createEmailGroup();
    } else if (+urlId > 0) {
      editEmailGroup(+urlId);
    }
    history.push('/email-groups');
  }

  // Create
  function createEmailGroup() {
    const payload = {
      email_group_name: groupName,
      email_group_description: groupDesc,
      user_ids: usersIdsInGroup,
    };

    fetch(`${API_BASE_URL}/api/v1/sec/email/email-distribution-group`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    })
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => {
        console.error(
          'There has been a problem with your fetch operation:',
          error,
        );
      });
  }

  // Edit
  function editEmailGroup(emailGroupId) {
    const payload = {
      email_group_name: groupName,
      email_group_description: groupDesc,
      user_ids: usersIdsInGroup,
    };

    fetch(
      `${API_BASE_URL}/api/v1/sec/email/email-distribution-group/${emailGroupId}`,
      {
        method: 'PUT',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + jwtToken,
        },
      },
    )
      .then((response) => response.json())
      .then((data) => console.log(data));
  }

  // Delete
  const handleConfirmDelete = (emailGroup) => {
    confirm({
      description: `The email group with id/name '${emailGroup.id}/${emailGroup.name}' will be permanently deleted from the database WCA_DB_DATA and will no longer be available to send emails to.`,
    })
      .then(() => {
        handleDelete(emailGroup.id);
      })
      .catch(() => {
        console.log(
          `Nope, not delete email group with id/name '${emailGroup.id}/${emailGroup.name}' from the database WCA_DB_DATA.`,
        );
      });
  };

  function handleDelete(emailGroupId) {
    fetch(
      `${API_BASE_URL}/api/v1/sec/email/email-distribution-group/${emailGroupId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + jwtToken,
        },
      },
    )
      .then((response) => response.json())
      .then(history.push('/email-groups'));
  }

  const handleBackBtn = () => {
    history.push('/email-groups');
  };

  return (
    <form onSubmit={handleSubmit}>
      <Container maxWidth="sm" sx={{ marginLeft: '0px' }}>
        <Stack spacing={3} mb={3}>
          <TextField
            id="outlined-basic"
            value={groupName}
            label="Email Group Name"
            variant="outlined"
            onChange={(event) => setGroupName(event.target.value)}
          />
          <TextField
            id="outlined-basic"
            value={groupDesc}
            label="Email Group Description"
            variant="outlined"
            onChange={(event) => setGroupDesc(event.target.value)}
          />
        </Stack>
      </Container>
      <TransferList
        urlId={urlId}
        users={users}
        usersIdsInGroup={usersIdsInGroup}
        setUsersIdsInGroup={setUsersIdsInGroup}
        p={3}
      />
      <Button variant="contained" type="submit" sx={{ mr: 2, ml: 3 }}>
        {urlId === 'new' ? 'Create' : 'Save'}
      </Button>
      <Button
        variant="outlined"
        onClick={() => handleConfirmDelete(emailGroup)}
      >
        Delete
      </Button>
      <Button variant="outlined" sx={{ ml: 2 }} onClick={() => handleBackBtn()}>
        Cancel
      </Button>
    </form>
  );
};
export default EmailGroupShow;
