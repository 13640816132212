import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import OrganisationList from './OrganisationList';
import OrganisationShow from './OrganisationShow';
import { Title } from 'react-admin';
import { Link, Box } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Auth } from '@aws-amplify/auth';
import { useParams } from 'react-router-dom';
import { API_BASE_URL } from '../utils/constants';
// import {Alert} from "@mui/lab";

// Create a theme that's used for @mui components
const customTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#4AA842',
      contrastText: '#222222',
    },
  },
});

if (!API_BASE_URL) {
  console.error('API_BASE_URL is not set!');
}

const OrganisationEntities = () => {
  const { tag } = useParams();
  const [organisations, setOrganisations] = useState([]);
  const [jwtToken, setJwtToken] = useState('');

  useEffect(() => {
    if (jwtToken) {
      loadOrganisations();
    }
  }, [jwtToken]);

  Auth.currentSession().then((user) => {
    setJwtToken(user?.accessToken?.jwtToken);
  });

  const loadOrganisations = () => {
    fetch(`${API_BASE_URL}/api/v1/sec/organisation/all`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Don't allow user_data value to be empty. Empty user_data means they are not in our DB...
        return data.filter((o) => o.deletedAt === null);
      })
      .then((data) => {
        if (!data.error) {
          setOrganisations(data);
        } else {
          console.error(data);
        }
      })
      .catch((err) => console.log(err));
  };

  // const loadOrganisation = ( orgTag ) => {
  //     fetch(`${API_BASE_URL}/api/v1/sec/cognito/admin/user/${uid}`, {
  //         method: 'GET',
  //         headers: {
  //             'Content-Type': 'application/json',
  //             Authorization: 'Bearer ' + jwtToken,
  //         },
  //     })
  //         .then((response) => response.json())
  //         .then((data) => {
  //             // Don't allow user_data value to be empty. Empty user_data means they are not in our DB...
  //             return data.filter((u) => u.user_data !== null);
  //         })
  //         .then((data) => {
  //             if (!data.error) {
  //                 setActiveUser(data);
  //                 // setUsers(formattedUsers);
  //             } else {
  //                 console.error(data);
  //             }
  //         });
  // };

  return (
    <ThemeProvider theme={customTheme}>
      <Title title="WCA Platform | Organisations Manager" />
      <Box sx={{ pt: 2, mb: 5 }}>
        <TopNav urlId={tag} />
      </Box>
      {tag === undefined && <OrganisationList organisations={organisations} />}
      {
                tag !== undefined && organisations.length > 0 && (
                <OrganisationShow
                    urlId={tag}
                    selectedOrg={organisations.find((o) => o.tag === tag)}
                    jwtToken={jwtToken}
                /> )
            }
    </ThemeProvider>
  );
};

const TopNav = ({ urlId }) => (
  <div role="presentation">
    <Breadcrumbs aria-label="breadcrumb">
      <Link underline="hover" href="/#/organisations">
        Organisations
      </Link>
      {urlId && (
        <Link underline="none" aria-current="page">
          Details
        </Link>
      )}
    </Breadcrumbs>
  </div>
);

export default OrganisationEntities;
