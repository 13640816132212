import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Checkbox from '@mui/material/Checkbox';
import { Button, Divider } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

const TransferList = ({
  urlId,
  users,
  usersIdsInGroup,
  setUsersIdsInGroup,
  groupName,
  ...otherProps
}) => {
  const initialRender = useRef(true);
  const initData = JSON.parse(localStorage.getItem('data'));
  const initDataUsersIdsInGroup = initData?.usersIdsInGroup;

  const [checked, setChecked] = React.useState([]);
  const usersIdsNotInGroup = useMemo(() => {
    return users
      .filter((u) => !usersIdsInGroup.includes(u.id))
      .map((u) => u.id);
  }, [users, usersIdsInGroup]);
  const [left, setLeft] = React.useState(usersIdsNotInGroup);
  const [right, setRight] = React.useState(
    initDataUsersIdsInGroup || usersIdsInGroup,
  );

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const [sortModeLeft, setSortModeLeft] = useState('a-z');
  const [sortModeRight, setSortModeRight] = useState('a-z');

  // Set initial data
  useEffect(() => {
    if (initialRender.current && usersIdsInGroup?.length && urlId !== 'new') {
      setLeft(usersIdsNotInGroup);
      setRight(initDataUsersIdsInGroup || usersIdsInGroup);
      initialRender.current = false;
    }
  }, [usersIdsNotInGroup, initDataUsersIdsInGroup, usersIdsInGroup, urlId]);

  // When the value of 'right' changes, notify the parent with it's data
  useEffect(() => {
    setUsersIdsInGroup(right);
  }, [right]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleSort = useCallback(
    (title, items) => {
      const userList = users?.filter((user) => {
        return items?.find((item) => user.id === item);
      });
      const sortedList = userList.sort((a, b) => {
        const nameA = a.firstName.toLowerCase();
        const nameB = b.firstName.toLowerCase();
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
      });

      if (title === 'Users') {
        if (sortModeLeft === 'a-z') {
          setLeft(sortedList.map((item) => item.id));
          setSortModeLeft('z-a');
        }
        if (sortModeLeft === 'z-a') {
          setLeft(sortedList.reverse().map((item) => item.id));
          setSortModeLeft('a-z');
        }
      }

      if (title === 'Email Group') {
        if (sortModeRight === 'a-z') {
          setRight(sortedList.map((item) => item.id));
          setSortModeRight('z-a');
        }
        if (sortModeRight === 'z-a') {
          setRight(sortedList.reverse().map((item) => item.id));
          setSortModeRight('a-z');
        }
      }
    },
    [users, sortModeLeft, sortModeRight],
  );

  const customList = (title, items) => (
    <Card>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          sx={{ px: 2, pt: 1, textTransform: 'none' }}
          variant="text"
          size="small"
          onClick={() => handleSort(title, items)}
        >
          Sort ({title === 'Users' ? sortModeLeft : sortModeRight})
        </Button>
      </div>
      <CardHeader
        sx={{ padding: '0 16px 10px 16px' }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              'aria-label': 'all items selected',
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: 300,
          height: 500,
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value, i) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem
              key={i}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={users.find((u) => u.id === value)?.displayName}
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <Grid
      container
      spacing={2}
      justifyContent="left"
      alignItems="center"
      marginBottom="20px"
      {...otherProps}
    >
      <Grid item>{customList('Users', left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList('Email Group', right)}</Grid>
    </Grid>
  );
};

export default TransferList;
