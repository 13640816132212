import React, { useEffect, useState } from 'react';
import CognitoUserList from "./CognitoUserList";
import CognitoUserShow from "./CognitoUserShow"
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Title } from 'react-admin';
import {
    Link,
    Box,
} from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Auth } from '@aws-amplify/auth';
import { useParams } from 'react-router-dom';

// Create a theme that's used for @mui components
const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#4AA842',
            contrastText: '#222222',
        },
    },
});

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
if (!API_BASE_URL) {
    console.error('API_BASE_URL is not set!');
}
// else {
//     console.log('API_BASE_URL', API_BASE_URL);
// }

const CognitoUsers = () => {
    const { uid } = useParams();
    const [users, setUsers] = useState([]);
    // const [activeUser, setActiveUser] = useState();
    const [jwtToken, setJwtToken] = useState('');
    const [, setShowAlertType] = useState('');

    useEffect(() => {
        if (jwtToken) {
            loadUsers();
        }
    }, [jwtToken]);



    Auth.currentSession().then((user) => {
        setJwtToken(user?.accessToken?.jwtToken);
    });

    // function handleRowClick(selectedIds) {
    //     const updatedArticles = articles.map((article) => {
    //         article._isChecked = selectedIds.includes(article.id);
    //         return article;
    //     });
    //     setArticles(updatedArticles);
    // }


    // const getAwsUserAttr = (user, attr) => {
    //     const value = user?.user_attributes.find((a) => a.name === attr)?.value;
    //     return value;
    // };

    const loadUsers = () => {
        fetch(`${API_BASE_URL}/api/v1/sec/cognito/admin/user/all`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + jwtToken,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                // Don't allow user_data value to be empty. Empty user_data means they are not in our DB...
                return data.filter((u) => u.user_data !== null);
            })
            .then((data) => {
                if (!data.error) {
                    // const formattedUsers = data.map((u) => ({
                    //     uid: u.user_data?.uid || 0,
                    //     email: u.email || '',
                    //     firstName:
                    //         getAwsUserAttr(u, 'custom:first_name') ||
                    //         u.user_data?.first_name ||
                    //         u.user_data?.preferred_name ||
                    //         '',
                    //     lastName:
                    //         getAwsUserAttr(u, 'custom:last_name') ||
                    //         u.user_data?.last_name ||
                    //         '',
                    //     get displayName() {
                    //         return `${this.firstName} ${this.lastName} ${this.email}`;
                    //     },
                    // }));
                    setUsers(data);
                    // setUsers(formattedUsers);
                } else {
                    console.error(data);
                }
            }).catch((err) => setShowAlertType('error'));
    };


    // const loadUser = ( uid ) => {
    //     fetch(`${API_BASE_URL}/api/v1/sec/cognito/admin/user/${uid}`, {
    //         method: 'GET',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             Authorization: 'Bearer ' + jwtToken,
    //         },
    //     })
    //         .then((response) => response.json())
    //         .then((data) => {
    //             // Don't allow user_data value to be empty. Empty user_data means they are not in our DB...
    //             return data.filter((u) => u.user_data !== null);
    //         })
    //         .then((data) => {
    //             if (!data.error) {
    //                 setActiveUser(data);
    //                 // setUsers(formattedUsers);
    //             } else {
    //                 console.error(data);
    //             }
    //         });
    // };





    return (
        <ThemeProvider theme={theme}>
            <Title title="WCA Platform | User/Cognito Manager" />
            <Box sx={{ pt: 2, mb: 5 }}>
                <TopNav id={uid} />
            </Box>
            {/*<CognitoUserList cognitoUsers={users} />*/}

            {
                uid === undefined && <CognitoUserList cognitoUsers={users} />
            }
            {
                uid !== undefined && users.length > 0 && (
                <CognitoUserShow
                    urlId={uid}
                    selectedUser={users.find((u) => u.username === uid)}
                    jwtToken={jwtToken}
                /> )
            }
            {/*<Fade in={showAlertType !== ''}>*/}
            {/*    <Box mt={3}>*/}
            {/*        {showAlertType === 'success' && (*/}
            {/*            <Alert severity="success">*/}
            {/*                Emails sent successfully!*/}
            {/*            </Alert>*/}
            {/*        )}*/}
            {/*        {showAlertType === 'error' && (*/}
            {/*            <Alert severity="error">*/}
            {/*                There was an issue trying to send emails*/}
            {/*            </Alert>*/}
            {/*        )}*/}
            {/*    </Box>*/}
            {/*</Fade>*/}
        </ThemeProvider>
    );
};


const TopNav = ({ id }) => (
    <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" href="/#/cognito-users">
                AWS Cognito Users
            </Link>
            {id && (
                <Link underline="none" aria-current="page">
                    Details
                </Link>
            )}
        </Breadcrumbs>
    </div>
);


export default CognitoUsers;
