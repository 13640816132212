import React from 'react';
// import React, {useState} from 'react';
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
  // GridValueSetterParams,
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
// import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import moment from 'moment';

const CognitoUserList = ({ cognitoUsers }) => {
  const history = useHistory();

  function getValue(user_attributes, field) {
    for (let i = 0; i < user_attributes.length; i++) {
      if (user_attributes[i].name === field) {
        return user_attributes[i].value;
      }
    }
    return null;
  }

  const columns = [
    {
      field: 'fullName',
      headerName: 'Name',
      width: 200,
      valueGetter: (params) => {
        // console.log( params.row.user_attributes );
        let result;

        const data_first_name = params.row.user_data?.first_name;
        const data_last_name = params.row.user_data?.last_name;

        const givenNameObject = getValue(
          params.row.user_attributes,
          'given_name',
        );
        const familyNameObject = getValue(
          params.row.user_attributes,
          'family_name',
        );

        if (data_first_name) {
          result = data_first_name;
        } else if (givenNameObject) {
          result = givenNameObject;
        }

        if (data_last_name) {
          result = result + ' ' + data_last_name;
        } else if (familyNameObject) {
          result = result + ' ' + familyNameObject;
        }

        return result;
      },
    },
    { field: 'email', headerName: 'Email address', width: 280 },
    { field: 'username', headerName: 'Cognito unique user Id', width: 300 },
    {
      field: 'preferred_name',
      headerName: 'Preferred name',
      width: 200,
      valueGetter: (params) => {
        return params.row.user_data?.preferred_name;
      },
    },
    { field: 'user_status', headerName: 'User status', width: 250 },
    {
      field: 'user_groups',
      headerName: 'User groups',
      width: 200,
      valueGetter: (params) => {
        return params.row.user_data?.user_groups;
      },
    },
    {
      field: 'company_name',
      headerName: 'Organisation',
      width: 140,
      valueGetter: (params) => {
        return params.row.user_data?.organisation_tag;
      },
    },
    {
      field: 'user_last_modified',
      headerName: 'Last modified at (UTC)',
      width: 200,
      valueGetter: (params) => {
        let date = params.row.user_last_modified;
        return moment(date).format('YYYY.MM.DD HH:mm:ss');
      },
    },
    {
      field: 'user_created',
      headerName: 'Created at (UTC)',
      width: 200,
      valueGetter: (params) => {
        let date = params.row.user_created;
        return moment(date).format('YYYY.MM.DD HH:mm:ss');
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          // <GridActionsCellItem
          //     icon={<DeleteIcon />}
          //     label="Delete"
          //     onClick={handleDeleteClick( id )}
          //     color="inherit"
          // />
        ];
      },
    },
  ];

  const handleEditClick = (urlId) => (event) => {
    history.push(`/cognito-users/${urlId}`);
  };

  // const handleDeleteClick = (urlId) => (event) => {
  //     history.push(`/cognito-users/${urlId}`);
  // };

  // make sure cells are not selectable...
  const CustomDataGrid = styled(DataGrid)(() => ({
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus':
      {
        outline: 'none',
      },
  }));

  function GridToolbar(props) {
    const handleClick = () => {
      history.push(`/cognito-users/new`);
    };

    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Add New WCA-Live User
        </Button>
      </GridToolbarContainer>
    );
  }

  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <div style={{ flexGrow: 1 }}>
        <CustomDataGrid
          rows={cognitoUsers}
          getRowId={(row) => row.username}
          columns={columns}
          components={{
            Toolbar: GridToolbar,
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: 'user_created', sort: 'desc' }],
            },
          }}
        />
      </div>
    </div>
  );
};

export default CognitoUserList;
