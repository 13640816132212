import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Title } from 'react-admin';
import { useParams } from 'react-router-dom';
import { Box, Link } from '@mui/material';
import EmailGroupList from './EmailGroupList';
import EmailGroupShow from './EmailGroupShow';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Auth } from '@aws-amplify/auth';
// import awsConfig from './../aws-exports';
// import { Amplify } from '@aws-amplify/core';

// Amplify.configure(awsConfig);

// Create a theme that's used for @mui components
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#4AA842',
      contrastText: '#222222',
    },
  },
});

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const EmailGroups = () => {
  const { id } = useParams();
  const [emailGroups, setEmailGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const [jwtToken, setJwtToken] = useState('');

  useEffect(() => {
    if (jwtToken) {
      if (emailGroups.length === 0) {
        loadEmailGroups();
      }
      loadUsers();
    }
  }, [jwtToken]);

  Auth.currentSession().then((user) => {
    setJwtToken(user?.accessToken?.jwtToken);
  });

  const loadEmailGroups = () => {
    fetch(`${API_BASE_URL}/api/v1/sec/email/email-distribution-group/all`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.error) {
          if (!Array.isArray(data.body)) {
            data.body = [];
          }
          const formattedEmailGroups = data.body.map((g) => ({
            id: g.id,
            name: g.email_group_name,
            desc: g.email_group_description,
            userIds: g.user_ids,
            userCount: g.user_ids?.length || 0,
          }));
          setEmailGroups(formattedEmailGroups);
        } else {
          console.error(data);
        }
      })
      .catch((error) => {
        console.error(
          'There has been a problem with your fetch operation:',
          error,
        );
      });
  };

  const getAwsUserAttr = (user, attr) => {
    const value = user?.user_attributes.find((a) => a.name === attr)?.value;
    return value;
  };

  const loadUsers = () => {
    fetch(`${API_BASE_URL}/api/v1/sec/cognito/admin/user/all`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Don't allow user_data value to be empty. Empty user_data means they are not in our DB?
        return data.filter((u) => u.user_data !== null);
      })
      .then((data) => {
        if (!data.error) {
          const formattedUsers = data.map((u) => ({
            id: u.user_data?.id || 0,
            email: u.email || '',
            firstName:
              getAwsUserAttr(u, 'custom:first_name') ||
              u.user_data?.first_name ||
              u.user_data?.preferred_name ||
              '',
            lastName:
              getAwsUserAttr(u, 'custom:last_name') ||
              u.user_data?.last_name ||
              '',
            get displayName() {
              return `${this.firstName} ${this.lastName} ${this.email}`;
            },
          }));
          setUsers(formattedUsers);
        } else {
          console.error(data);
        }
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Title title="WCA Platform | Email Groups" />
      <Box sx={{ pt: 2, mb: 5 }}>
        <TopNav id={id} />
      </Box>
      {id === undefined && <EmailGroupList emailGroups={emailGroups} />}
      {id !== undefined && users.length > 0 && (
        <EmailGroupShow
          urlId={id}
          users={users}
          emailGroup={emailGroups?.find((g) => g.id === +id)}
          jwtToken={jwtToken}
        />
      )}
    </ThemeProvider>
  );
};

const TopNav = ({ id }) => (
  <div role="presentation">
    <Breadcrumbs aria-label="breadcrumb">
      <Link underline="hover" href="/#/email-groups">
        Email Groups
      </Link>
      {id && (
        <Link underline="none" aria-current="page">
          Details
        </Link>
      )}
    </Breadcrumbs>
  </div>
);

export default EmailGroups;
