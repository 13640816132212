import { MAuthor } from './author.model';
import { MPdf } from './pdf.model';
import { MTagList } from './tagList.model';

export class ArticleModel {
  constructor(dataArticle, strapi_content_type) {
    this.id = undefined;
    this.title = undefined;
    this.description = undefined;
    this.publishedAt = undefined;
    this.createdAt = undefined;
    this.updatedAt = undefined;
    this.detail = undefined;
    this.date = undefined;
    this.type = undefined;
    this.slug = undefined;
    this.in_progress = undefined;
    this.english_content = undefined;
    this.content_source_url = undefined;
    this.chinese_content = undefined;
    this.tags_backup = undefined;
    this.vimeo_video_id = undefined;
    this.vimeo_data = undefined;
    this.important = undefined;
    this.tag_list = undefined;
    this.author = undefined;
    this.countries = undefined;
    this.pdf = undefined;
    this.chart_image = undefined;
    this.image = undefined;
    this.images = undefined;
    this.charts = undefined;
    this.country_list = undefined;
    this.strapi_content_type = undefined;
    this.setData(dataArticle, strapi_content_type);
  }
  setData(data, strapi_content_type) {
    this.id = data.id;
    this.title = data?.attributes?.title;
    this.description = data.attributes.description;
    this.publishedAt = data.attributes.publishedAt;
    this.createdAt = data.attributes.createdAt;
    this.updatedAt = data.attributes.updatedAt;
    this.detail = data.attributes.detail;
    this.date = data.attributes.date;
    this.type = data.attributes.type;
    this.slug = data.attributes.slug;
    this.in_progress = data.attributes.in_progress;
    this.english_content = data.attributes.english_content;
    this.content_source_url = data.attributes.content_source_url;
    this.chinese_content = data.attributes.chinese_content;
    this.tags_backup = data.attributes.tags_backup;
    this.vimeo_video_id = data.attributes.vimeo_video_id;
    this.vimeo_data = data.attributes.vimeo_data;
    this.important = data.attributes.important;
    this.tag_list = data?.attributes.tag_list?.map((el) => {
      return new MTagList(el);
    });
    this.author = (data?.attributes.authors || []).map((el) => {
      return new MAuthor(el);
    });
    this.countries = data.attributes.countries;
    this.pdf = data?.attributes?.pdf.data
      ? new MPdf(data?.attributes?.pdf?.data)
      : null;

    this.chart_image = data.attributes.chart_image;
    this.image = data.attributes.image;
    this.images = data.attributes.images;
    this.charts = data.attributes.charts;
    this.country_list = data.attributes.country_list;
    this.strapi_content_type = strapi_content_type;
  }
}
