export class MMeta {
  constructor(meta) {
    this.page = 0;
    this.pageSize = 0;
    this.pageCount = 0;
    this.total = 0;
    this.setData(meta);
  }

  setData(meta) {
    this.page = meta?.pagination?.page || 0;
    this.pageSize = meta?.pagination?.pageSize || 0;
    this.pageCount = meta?.pagination?.pageCount || 0;
    this.total = meta?.pagination?.total || 0;
  }
}
