import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import { Title } from 'react-admin';

// Create a theme that's used for @mui components
// const customTheme = createTheme({
//   palette: {
//     mode: 'dark',
//     secondary: {
//       main: '#18212D',
//       contrastText: '#ffffff',
//     },
//   },
// });

const CustomDataGrid = styled(DataGrid)(() => ({
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus':
    {
      outline: 'none',
    },
}));
const data = [
  { title: 'Mehmet', chart: 'Baran', select: 1987, id: 63 },
  { title: 'Mehmet', chart: 'Baran', select: 1987, id: 62 },
  { title: 'Mehmet', chart: 'Baran', select: 1987, id: 12 },
  { title: 'Mehmet', chart: 'Baran', select: 1987, id: 56 },
  { title: 'Mehmet', chart: 'Baran', select: 1987, id: 78 },
];

const columns = [
  {
    field: 'title',
    headerName: 'Chartpack Name',
    width: 250,
    editable: false,
  },
  {
    field: 'chart',
    headerName: '# of charts',
    width: 200,
    editable: false,
    // valueGetter: (params) => {
    //   let date = params.row.published_at;
    //   return moment(date).format('DD.MM.YYYY HH:mm:ss');
    // },
  },
  {
    field: 'select',
    headerName: 'Selected',
    width: 150,
    editable: false,
  },
  //   { field: 'id', headerName: 'ID', width: 90 },
];
const ChartPacks = ({ theme }) => {
  //! State
  //! Function
  //! Render
  return (
    // <ThemeProvider theme={customTheme}>
    <Box
      sx={{
        height: 450,
        width: '100%',
        // backgroundColor: '#233246',
      }}
    >
      <Title title="WCA Platform | Chartpacks" />
      <CustomDataGrid
        //   pagination
        style={{ color: 'white' }}
        rows={data}
        columns={columns}
        //   rowCount={count}
        //   pageSize={LIMIT}
        //   loading={loading}
        //   rowsPerPageOptions={[LIMIT]}
        checkboxSelection
        //   page={page}
        //   onPageChange={onPageChange}
        isCellEditable={false}
        // onSelectionModelChange={(newSelectionModel, detail) => {
        //   handleRowClick(newSelectionModel);
        // }}
        // selectionModel={allowMultiSelect ? selectedArticle : selectedArticle[0]}

        // onSelectionModelChange={onSelectionModelChange}
        selectionModel
        //   onRowClick={onRowClick}
      />
    </Box>
    // </ThemeProvider>
  );
};
export default ChartPacks;
