import { useEffect, useState } from 'react';
import { Card, CardContent } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {  TextField } from '@mui/material';

const ConfigurationPage = () => {
  const [state, setState] = useState();
  useEffect(() => {
    findUserData();
    filterUserData();
  }, []);
  const findUserData = () => {
    Object.keys(localStorage).map((el) => {
      if (el.includes('userData')) {
        setState(JSON.parse(localStorage.getItem(el)));
      }
    });
  };
  const filterUserData = () => {};
  console.log(state);

  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h4" size="sm">
            Profile
          </Typography>
        </CardContent>
        {state
          ? state?.UserAttributes.map((el, index) => {
              if (el.Name === 'phone_number' || el.Name === 'email') {
                return (
                  <CardContent>
                    {/* <InputLabel style={{color: 'white'}} htmlFor={`outlined-read-only-input-${index}`}>{el.Name.toUpperCase()}</InputLabel> */}
                    <TextField
                      id={`outlined-read-only-input-${index}`}
                      name={`outlined-read-only-input-${index}`}
                      label={el.Name.toUpperCase()}
                      defaultValue={el.Value}
                      fullWidth
                      variant='filled'
                      inputProps={{style: {color: 'white'}}}
                      InputLabelProps={{ style: {color: 'whitesmoke', fontSize: '1rem'}}}
                      InputProps={{
                        readOnly: true,
                        style: {backgroundColor: 'rgb(28, 38, 51)'}
                      }}
                      // focused
                    />
                  </CardContent>
                );
              } else {
                return null;
              }
            })
          : null}
      </Card>
    </>
  );
};
export default ConfigurationPage;
