import * as React from 'react';
import {
  AppBar,
  UserMenu,
  MenuItemLink,
  useTranslate,
  RefreshIconButton,
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/core/styles';
import ConfirmAlert from '../confirm-alert';
import { StepContext } from '../App';

// import Logo from './Logo';

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
});

const ConfigurationMenu = React.forwardRef((props, ref) => {
  const translate = useTranslate();
  return (
    <MenuItemLink
      ref={ref}
      to="/configuration"
      primaryText={translate('Profile Settings')}
      leftIcon={<SettingsIcon />}
      onClick={props.onClick}
      sidebarIsOpen
    />
  );
});

const CustomUserMenu = (props) => (
  <UserMenu {...props}>
    <ConfigurationMenu />
  </UserMenu>
);

const CustomAppBar = (props) => {
  const store = React.useContext(StepContext);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleReload = () => {
    store.setClickRefesh(!store.clickRefesh);
    setOpen(true);
  };
  const handleClose = (type) => {
    switch (type) {
      case 'yes':
        localStorage.setItem('data', JSON.stringify(store.value));
        setOpen(false);
        window.location.reload();
        break;
      case 'no':
        setOpen(false);
        localStorage.removeItem('data');
        window.location.reload();
        break;
      default:
        setOpen(false);
        break;
    }
  };
  return (
    <AppBar {...props} elevation={1} userMenu={<CustomUserMenu />}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      {/* <Logo /> */}
      {/* <span>asjdasjdalk</span>
      <span className={classes.spacer} /> */}
      <ConfirmAlert open={open} handleClose={handleClose} />
      <RefreshIconButton label="custom-refresh" onClick={handleReload} />
    </AppBar>
  );
};

export default CustomAppBar;
