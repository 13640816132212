import SendIcon from '@material-ui/icons/Send';
import EmailWeekly from '../email-weekly';

const sendEmail = {
  list: EmailWeekly,
  icon: SendIcon,
  // options: { label: 'Email Automation' },
};

export default sendEmail;
