import React, { useEffect, useState } from 'react';
import { Typography, Box, Checkbox } from '@mui/material';
import map from 'lodash/map';

const Step2 = ({ rowDetail, isAttachedChart, setAttachedChart }) => {
  const [havePDF, setHavePDF] = useState(false);

  useEffect(() => {
    const PDF = rowDetail.map((obj) => obj.pdf);
    if (PDF.includes(null)) {
      setHavePDF(false);
    } else {
      setHavePDF(true);
    }
  }, [rowDetail]);

  const idArticle = rowDetail.map((obj) => obj.id);
  const titleArticle = rowDetail.map((obj) => obj.title);
  console.log("rowDetail", rowDetail);
  const handleChange = (event) => {
    setAttachedChart(event.target.checked);
  };

  if (rowDetail?.length > 1) {
    return (
      <>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <Box sx={{ width: '100%' }}>
          <Typography sx={{ whiteSpace: 'nowrap' }}>
            Multiple articles selected{' '}
              {map(titleArticle, (item, index) => (
                <b style={{ color: '#5b85d7' }}>
                  {item} (ID:{idArticle[index]}){+index !== (titleArticle.length - 1) && ','}&nbsp;
                </b>
              ))}
          </Typography>
          <Box>Can't attach chartpack for multiple shortforms. Please press continue</Box>
        </Box>
      </Box>
    </>
    )
  }

  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        {havePDF ? (
          <Box sx={{ width: '100%' }}>
            <Typography sx={{ whiteSpace: 'nowrap' }}>
              Selected article{' '}
              <b style={{ color: '#5b85d7' }}>
                {titleArticle} (ID:{idArticle})
              </b>{' '}
              includes a chartpack
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  backgroundColor: 'white',
                  marginRight: '10px',
                  width: '16px',
                  height: '16px',
                  position: 'relative',
                }}
              >
                <Checkbox
                  checked={isAttachedChart}
                  onChange={handleChange}
                  sx={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    color: '#0099FF',
                    padding: '0',
                    width: '100%',
                    height: '100%',

                    '& .MuiSvgIcon-root': { fontSize: '24px' },

                    '&.Mui-checked': {
                      color: '#0099FF',
                    },
                  }}
                />
              </Box>
              <Typography> click to attach chartpack to email</Typography>
            </Box>
          </Box>
        ) : (
          <Box sx={{ width: '100%' }}>
            <Typography sx={{ whiteSpace: 'nowrap' }}>
              Selected article{' '}
              <b style={{ color: '#5b85d7' }}>
                {titleArticle} (ID:{idArticle})
              </b>{' '}
              does not includes a chartpack
            </Typography>
            <Box>No chartpack to attach. Please press continue</Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Step2;
