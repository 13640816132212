import * as React from 'react';
import plotly from 'plotly.js/dist/plotly';
import PlotlyEditor from 'react-chart-editor';
import 'react-chart-editor/lib/react-chart-editor.css';
import { Title } from 'react-admin';
const dataSources = {
  col1: [1, 2, 3], // eslint-disable-line no-magic-numbers
  col2: [4, 3, 2], // eslint-disable-line no-magic-numbers
  col3: [17, 13, 9], // eslint-disable-line no-magic-numbers
};
const dataSourceOptions = Object.keys(dataSources).map((name) => ({
  value: name,
  label: name,
}));
const config = { editable: true };

const ChartBuilder = () => {
  const [state, setState] = React.useState({
    data: [
      {
        type: 'scatter',
        x: dataSources.col1,
        y: dataSources.col2,
        marker: { color: dataSources.col3 },
      },
    ],
    layout: {},
    frames: [],
  });
  return (
    <div
      className="ploty-chart"
      style={{ height: '100%', backgroundColor: 'white' }}
    >
      <Title title="WCA Platform | Chart Builder" />

      <PlotlyEditor
        data={state.data}
        layout={state.layout}
        config={config}
        frames={state.frames}
        dataSources={dataSources}
        dataSourceOptions={dataSourceOptions}
        plotly={plotly}
        onUpdate={(data, layout, frames) => setState({ data, layout, frames })}
        useResizeHandler
        debug
        advancedTraceTypeSelector
      >
        {/* <CustomEditor /> */}
      </PlotlyEditor>
    </div>
  );
};
export default ChartBuilder;
// {
//   list: PlotyChart,
//   icon: SendIcon,
// };
