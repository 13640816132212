import { makeStyles } from '@material-ui/core/styles';
import CategoryIcon from '@material-ui/icons/Bookmark';
import DashboardIcon from '@material-ui/icons/Dashboard';
import classnames from 'classnames';
import * as React from 'react';
import { useState } from 'react';
import { MenuItemLink } from 'react-admin';
import { useSelector } from 'react-redux';
import { ReactComponent as AutoGraphIcon } from '../assets/svg/AutoGraph.svg';
import { ReactComponent as InsertChartOutlinesIcon } from '../assets/svg/InsertChartOutlines.svg';
import { ReactComponent as InsightsIcon } from '../assets/svg/Insights.svg';
import { ReactComponent as LeaderboardIcon } from '../assets/svg/Leaderboard.svg';
import { ReactComponent as QueryStatsIcon } from '../assets/svg/QueryStats.svg';
import cognito from '../cognito-new';
import emailGroups from '../email-groups-new';
import organisations from '../organisation-new';
import sendEmail from '../send-email';
import SubMenu from './SubMenu';

const Menu = ({ dense = false, className }) => {
  const [state, setState] = useState({
    menuOrganisation: false,
    cognito: false,
    sendEmail: false,
    ChartPlotly: false,
  });

  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  useSelector((state) => state.theme); // force rerender on theme change
  const classes = useStyles();

  const handleToggle = (menu) => {
    setState((state) => ({ ...!state, [menu]: !state[menu] }));
  };

  return (
    <div
      className={classnames(
        classes.root,
        {
          [classes.open]: open,
          [classes.closed]: !open,
        },
        'w-300',
      )}
    >
      {/* <DashboardMenuItem onClick={() => handleToggle('')}/> */}
      <MenuItemLink
        className="w-300"
        to={{
          pathname: '/',
          state: { _scrollToTop: true },
        }}
        primaryText="Dashboard"
        leftIcon={<DashboardIcon />}
        dense={dense}
        onClick={() => handleToggle('')}
      />
      <SubMenu
        handleToggle={() => handleToggle('menuOrganisation')}
        isOpen={state.menuOrganisation}
        name="Organisations"
        icon={<organisations.icon style={{ paddingRight: '1rem' }} />}
        dense={dense}
      >
        <MenuItemLink
          className="w-300"
          to={{
            pathname: '/organisations',
            state: { _scrollToTop: true },
          }}
          primaryText="List"
          leftIcon={<CategoryIcon />}
          dense={dense}
        />
        {/* <MenuItemLink
          to={{
            pathname: '/new',
            state: { _scrollToTop: true },
          }}
          primaryText="Create"
          leftIcon={<organisations.icon />}
          dense={dense}
        /> */}
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle('cognito')}
        isOpen={state.cognito}
        name="User Management"
        icon={<cognito.icon style={{ paddingRight: '1rem' }} />}
        dense={dense}
      >
        <MenuItemLink
          to={{
            pathname: '/cognito-users',
            state: { _scrollToTop: true },
          }}
          primaryText="List"
          leftIcon={<CategoryIcon />}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle('sendEmail')}
        isOpen={state.sendEmail}
        name="Email Automation"
        icon={<emailGroups.icon style={{ paddingRight: '1rem' }} />}
        dense={dense}
      >
        <MenuItemLink
          to={{
            pathname: '/email-groups',
            state: { _scrollToTop: true },
          }}
          primaryText="Email Groups"
          leftIcon={<emailGroups.icon />}
          dense={dense}
        />
        <MenuItemLink
          to={{
            pathname: '/email',
            state: { _scrollToTop: true },
          }}
          primaryText="Shortforms (China Weekly News)"
          leftIcon={<sendEmail.icon />}
          dense={dense}
        />
        <MenuItemLink
          to={{
            pathname: '/email-longform',
            state: { _scrollToTop: true },
          }}
          primaryText="Longform"
          leftIcon={<sendEmail.icon />}
          dense={dense}
        />
        <MenuItemLink
          to={{
            pathname: '/email-macrowatch',
            state: { _scrollToTop: true },
          }}
          primaryText="Macrowatch"
          leftIcon={<sendEmail.icon />}
          dense={dense}
        />
        <MenuItemLink
          to={{
            pathname: '/email-weekly',
            state: { _scrollToTop: true },
          }}
          primaryText="Week with Wigram"
          leftIcon={<sendEmail.icon />}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle('ChartPlotly')}
        isOpen={state.ChartPlotly}
        name="Plotly Chart"
        icon={<AutoGraphIcon fill="white" style={{ paddingRight: '1rem' }} />}
        dense={dense}
      >
        <MenuItemLink
          to={{
            pathname: '/chart',
            state: { _scrollToTop: true },
          }}
          primaryText="Chart"
          leftIcon={<LeaderboardIcon fill="white" />}
          dense={dense}
        />
        <MenuItemLink
          to={{
            pathname: '/chart-packs',
            state: { _scrollToTop: true },
          }}
          primaryText="Chartpacks"
          leftIcon={<InsertChartOutlinesIcon fill="white" />}
          dense={dense}
        />
        <MenuItemLink
          to={{
            pathname: '/datasources',
            state: { _scrollToTop: true },
          }}
          primaryText="Datasources"
          leftIcon={<InsightsIcon fill="white" />}
          dense={dense}
        />
        <MenuItemLink
          to={{
            pathname: '/chart-builder',
            state: { _scrollToTop: true },
          }}
          primaryText="Chart Builder"
          leftIcon={<QueryStatsIcon fill="white" />}
          dense={dense}
        />
      </SubMenu>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 320,
  },
  open: {
    width: 320,
  },
  closed: {
    width: 55,
  },
}));

export default Menu;
