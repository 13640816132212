import axios from 'axios';
import { API_BASE_URL } from '../utils/constants';

const apiService = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 120000,
});

export const addToken = (token) => {
  apiService.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export default apiService;
