export class MPdf {
  constructor(dataPdf) {
    this.id = undefined;
    this.name = undefined;
    this.alternativeText = undefined;
    this.caption = undefined;
    this.width = undefined;
    this.height = undefined;
    this.formats = undefined;
    this.hash = undefined;
    this.ext = undefined;
    this.mime = undefined;
    this.size = undefined;
    this.url = undefined;
    this.previewUrl = undefined;
    this.provider = undefined;
    this.provider_metadata = undefined;
    this.created_at = undefined;
    this.updated_at = undefined;
    this.setData && this.setData(dataPdf);
  }
  setData(data) {
    this.id = data?.id;
    this.name = data?.attributes?.name;
    this.alternativeText = data?.attributes?.alternativeText;
    this.caption = data?.attributes?.caption;
    this.width = data?.attributes?.width;
    this.height = data?.attributes?.height;
    this.formats = data?.attributes?.formats;
    this.hash = data?.attributes?.hash;
    this.ext = data?.attributes?.ext;
    this.mime = data?.attributes?.mime;
    this.size = data?.attributes?.size;
    this.url = data?.attributes?.url;
    this.previewUrl = data?.attributes?.previewUrl;
    this.provider = data?.attributes?.provider;
    this.provider_metadata = data?.attributes?.provider_metadata;
    this.created_at = data?.attributes?.createdAt;
    this.updated_at = data?.attributes?.updatedAt;
  }
}
