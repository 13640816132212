import React, { useCallback, useEffect, useState, useContext } from 'react';
import { TextField, Box } from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import useGetEmailGroups from '../hooks/useGetEmailGroups';
import useGetLoadUsers from '../hooks/useGetLoadUsers';
import { StepContext } from '../App';

const Step3 = ({ onWatchStep2 }) => {
  //! Context
  const store = useContext(StepContext);
  //! State
  const { emailGroups } = useGetEmailGroups(getSampleEmailGroups());
  const { users } = useGetLoadUsers();

  const [emailTo, setEmailTo] = useState(store?.value?.step2Data.emailTo || '');
  const [emailsTo, setEmailsTo] = useState(
    store?.value?.step2Data.emailsTo || [],
  );
  const [subject, setSubject] = useState(store?.value?.step2Data.subject || '');
  const [sendToTabValue, setSendToTabValue] = useState('single');
  const [selectedEmailGroup, setSelectedEmailGroup] = useState({}); // email group with id = 1
  const [emailFrom, setEmailFrom] = useState(
    store?.value?.step2Data.emailFrom || 'rodney@wigramcap.com',
  );

  //! Effect
  useEffect(() => {
    onWatchStep2 &&
      onWatchStep2({
        emailTo,
        emailsTo,
        emailGroups,
        sendToTabValue,
        emailFrom,
        selectedEmailGroup,
        subject,
      });
  }, [
    onWatchStep2,
    emailTo,
    emailsTo,
    emailGroups,
    sendToTabValue,
    emailFrom,
    selectedEmailGroup,
    subject,
  ]);

  //! Function
  // Given a list of userIds, return a list of user emails
  const userEmails = useCallback(
    (userIds = []) => {
      return users
        ?.filter((u) => userIds.includes(u.user_data?.id))
        ?.map((u) => u.email);
    },
    [users],
  );

  const handleTabChange = (event, newValue) => {
    setSendToTabValue(newValue);
  };

  const handleEmailGroupChange = (event) => {
    setSelectedEmailGroup(event.target.value);
  };

  // When selectedEmailGroup changes, update the emailsTo
  useEffect(() => {
    if (selectedEmailGroup) {
      const emailGroup = emailGroups.find((g) => g.id === selectedEmailGroup.id);
      setEmailsTo(userEmails(emailGroup?.userIds));
    }
  }, [selectedEmailGroup, userEmails, emailGroups]);

  //! Render
  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={sendToTabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleTabChange} aria-label="tabs">
              <Tab label="Single Email Address" value="single" />
              <Tab label="Email Group" value="group" />
            </TabList>
          </Box>
          <TabPanel value="single" sx={{ p: 0, pt: 4 }}>
            {/* Single Email Address */}
            <TextField
              id="outlined-basic"
              label="Email To *"
              variant="outlined"
              fullWidth
              value={emailTo}
              sx={{ width: '50ch' }}
              onChange={({ target: { value } }) => {
                setEmailTo(value);
              }}
            />
          </TabPanel>
          <TabPanel value="group" sx={{ p: 0, pt: 4 }}>
            {/* Email Group */}
            {emailGroups?.length > 0 && (
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Email To Group
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  sx={{ width: '50ch' }}
                  value={selectedEmailGroup}
                  label="Email To Group"
                  onChange={handleEmailGroupChange}
                >
                  {emailGroups.map((group) => (
                    <MenuItem value={group} key={group.id}>
                      {`${group.name} (${group.userCount || 0} clients)`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </TabPanel>
        </TabContext>
        <Box sx={{ width: '100%', typography: 'body1', mt: 4 }}>
          <TextField
            id="outlined-basic"
            label="Email Subject"
            variant="outlined"
            fullWidth
            value={subject}
            sx={{ width: '50ch' }}
            onChange={({ target: { value } }) => {
              setSubject(value);
            }}
          />
        </Box>
        <Box sx={{ width: '100%', typography: 'body1', mt: 4 }}>
          <InputLabel id="demo-simple-select-standard-label">
            Email Sender
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={emailFrom}
            label="Age"
            onChange={({ target: { value } }) => {
              setEmailFrom(value);
            }}
          >
            <MenuItem value={'default'}>default</MenuItem>
            <MenuItem value={'rodney@wigramcap.com'}>
              rodney@wigramcap.com
            </MenuItem>
            <MenuItem value={'research@wigramcap.com'}>
              research@wigramcap.com
            </MenuItem>
          </Select>
        </Box>
      </Box>
    </>
  );
};
// TODO: Replace with API call to fetch email groups
const getSampleEmailGroups = () => {
  return [
    {
      id: 1,
      name: 'AUS/NZ',
      emails: ['user1@company.com', 'user43@company.com', 'user12@company.com'],
    },
    {
      id: 2,
      name: 'Asia',
      emails: [
        'user3849@company.com',
        'user12@company.com',
        'user49@company.com',
      ],
    },
    {
      id: 3,
      name: 'VIP Clients',
      emails: ['user3486@company.com', 'user1113@company.com'],
    },
  ];
};
export default Step3;
