export class MAuthor {
  constructor(dataAuthor) {
    this.id = undefined;
    this.name = undefined;
    this.createdAt = undefined;
    this.updatedAt = undefined;
    this.email = undefined;
    this.setData && this.setData(dataAuthor);
  }

  setData(data) {
    this.id = data?.author?.data?.id;
    this.name = data?.author?.data?.attributes?.name;
    this.createdAt = data?.author?.data?.attributes?.createdAt;
    this.updatedAt = data?.author?.data?.attributes?.updatedAt;
    this.email = data?.author?.data?.attributes?.email;
  }
}
