import React from 'react';
import { Route } from 'react-router-dom';
// import ChartPlotly from './chart-builder';
import ChartBuilder from './chart-builder';
import ChartPacks from './chart-packs';
import CognitoUsers from './cognito';
import ConfigurationPage from './configurationPage/configurationPage';
import Email from './email';
import EmailGroups from './email-groups';
import EmailWeekly from './email-weekly';
import OrganisationEntities from './organisation';
import EmailLongform from './email-longform';
import EmailMacrowatch from './email-macrowatch';

const routes = [
  <Route exact path="/email-groups/:id" component={EmailGroups} />,
  <Route exact path="/cognito-users/:uid" component={CognitoUsers} />,
  <Route exact path="/organisations/:tag" component={OrganisationEntities} />,
  <Route exact path="/email" component={Email} />,
  <Route exact path="/email-weekly" component={EmailWeekly} />,
  <Route exact path="/configuration" component={ConfigurationPage} />,
  <Route exact path="/chart-plotly/:id" />,
  <Route exact path="/chart-plotly" />,
  <Route exact path="/chart-packs" component={ChartPacks} />,
  <Route exact path="/datasources" />,
  <Route exact path="/chart-builder" component={ChartBuilder} />,
  <Route exact path="/email-longform" component={EmailLongform} />,
  <Route exact path="/email-macrowatch" component={EmailMacrowatch} />,
];

export default routes;
