import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ConfirmAlert = ({ open, handleClose }) => {
  return (
    <>
      <Dialog
        className="confirm"
        open={open}
        onClose={handleClose}
        // PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Are you sure?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            There is unsaved data. Do you want to first save the unsaved data
            before reloading?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => handleClose('cancel')}>
            Cancel
          </Button>
          <Button onClick={() => handleClose('no')}>No</Button>
          <Button
            onClick={() => {
              handleClose('yes');
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ConfirmAlert;
